import { Edit, RestartAlt } from "@mui/icons-material";
import {
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Slider,
  Switch,
  ToggleButton,
  Typography,
  styled,
} from "@mui/material";
import { FC, MutableRefObject, useEffect, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { SCREENLINE_WIDTH_FACTOR } from "features/map/modules/screenlines/map-data/layers";
import { setScreenlineLayersRoadClasses, setScreenlineLayersWidthFactor } from "features/screenline";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions, selectArePendingChanges, selectScreenlinesLoading } from "store/sections/screenlines";

export interface ScreenlineMapLayersProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  screenlinesModuleData: ModuleData;
}

export const InlineGridItem = styled(Grid)(({ theme }) => ({
  "&.MuiGrid-item": {
    paddingTop: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

function calculateScreenlineWidthFactor(value: number) {
  return value ** 3;
}

function valueLabelFormat(value: number) {
  return `x ${Math.round(value * 100) / 100}`;
}

export const ScreenlineMapLayers: FC<ScreenlineMapLayersProps> = ({ map, screenlinesModuleData }) => {
  const { changeShowScreenlines } = screenlinesModuleData.data;

  const dispatch = useAppDispatch();
  const isScreenlinesEditorOpen = useAppSelector((state) => state.screenlines.isScreelineEditorOpen);
  const loading = useAppSelector(selectScreenlinesLoading);
  const screenlinesMaxCount = useAppSelector((state) => state.screenlines.maxCount);
  const arePendingChanges = useAppSelector(selectArePendingChanges);
  const roadClasses = useAppSelector((state) => state.filters.roadClasses);

  const showScreenlines = useAppSelector((state) => state.screenlines.showScreenlines);
  const [screenlineWidth, setScreenlineWidth] = useState(1);

  const handleToggleScreenlineEditor = () => {
    if (!arePendingChanges || window.confirm("Changes that you made may not be saved."))
      dispatch(screenlinesActions.setScreelineEditorOpen(!isScreenlinesEditorOpen));
  };

  const handleChangeScreenlineWidth = (newValue: number | number[]) => {
    setScreenlineWidth(newValue as number);
  };

  useEffect(() => {
    if (map.current) {
      const widthFactor = calculateScreenlineWidthFactor(screenlineWidth) * SCREENLINE_WIDTH_FACTOR;
      setScreenlineLayersWidthFactor(map.current, widthFactor, screenlinesMaxCount || 1);
    }
  }, [map, screenlineWidth, screenlinesMaxCount]);

  useEffect(() => {
    if (map.current) {
      setScreenlineLayersRoadClasses(map.current, roadClasses);
    }
  }, [map, roadClasses]);

  return (
    <>
      <Grid container alignItems={"center"} justifyContent={"space-between"} marginBottom={1}>
        <FormControlLabel
          label="Screenlines"
          control={<Switch checked={showScreenlines} style={{ margin: 0 }} onChange={changeShowScreenlines} />}
        />
        <ToggleButton
          sx={{ height: "30px", textTransform: "none" }}
          size={"small"}
          color="primary"
          value={true}
          selected={isScreenlinesEditorOpen}
          onClick={handleToggleScreenlineEditor}
        >
          <Edit fontSize="inherit" sx={{ marginRight: 1 }} />
          Edit
        </ToggleButton>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <InlineGridItem minWidth={65} height={40} item>
          <Typography variant="caption" fontWeight={500}>
            Width
          </Typography>
        </InlineGridItem>
        <InlineGridItem height={40} item xs>
          <Slider
            disabled={loading}
            sx={{
              marginTop: "0",
              "& .MuiSlider-mark, .MuiSlider-markActive": {
                width: "6px",
                height: "6px",
                borderRadius: "50%",
              },
            }}
            color="secondary"
            size="small"
            value={screenlineWidth}
            min={0}
            max={2}
            step={0.1}
            marks={[
              {
                value: 1,
                label: "",
              },
            ]}
            scale={calculateScreenlineWidthFactor}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay="auto"
            onChange={(e, value) => handleChangeScreenlineWidth(value)}
          />
        </InlineGridItem>
        <InlineGridItem height={40} item>
          <IconButton size="small" onClick={() => handleChangeScreenlineWidth(1)}>
            <RestartAlt fontSize={"small"} />
          </IconButton>
        </InlineGridItem>
      </Grid>
      <Divider sx={{ marginBottom: 1 }} />
    </>
  );
};
