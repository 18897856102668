import { Tab } from "@blueprintjs/core";
import { ChangeEvent, FC, useEffect } from "react";

import { CheckboxDropdown, InfoCard, MeasureIcon, Tabs } from "components";

import { useAppSelector } from "hooks";

import { FiltersType, Measure, MeasureType } from "types";

import { areAllItemsUnChecked, getIconByDimension } from "./Filters";

export interface RoadsFiltersPanelProps {
  filters: FiltersType | null;
  roadMeasures: Measure[] | undefined;
  currentRoadMeasure: Measure | null;
  measure: MeasureType;
  loading: boolean;
  disabled?: boolean;
  isAnalysis?: boolean;
  setMeasure: (measure: MeasureType) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAllFilters: (isChecked: boolean) => (groupName: string) => void;
}

export const RoadsFiltersPanel: FC<RoadsFiltersPanelProps> = ({
  filters,
  roadMeasures,
  currentRoadMeasure,
  measure,
  loading,
  disabled,
  isAnalysis,
  setMeasure,
  handleChangeFilter,
  handleChangeAllFilters,
}) => {
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  useEffect(() => {
    if (!currentRoadMeasure) {
      setMeasure(MeasureType.AADT);
    }
  }, [currentRoadMeasure, measure, setMeasure]);

  const handleTabChange = (measure: MeasureType) => {
    setMeasure(measure);
  };

  const getPanelByMeasure = () => {
    if (filters && Object.keys(filters).length > 0) {
      return (
        <>
          {Object.entries(filters)
            // @TODO: Remove vehicle_type filter for SL (we need to remove this after implementing the new SL metadata)
            .filter(([filterKey]) => !(isAnalysis && filterKey === "vehicle_type"))
            .map(([filterKey, filterValues]) => (
              <CheckboxDropdown
                key={filterKey}
                isGroupChecked={filterValues.isChecked}
                groupName={filterKey}
                groupLabel={filterValues.label}
                groupIcon={filterValues.icon}
                items={filterValues.items}
                placement="top"
                disabled={loading || disabled}
                error={areAllItemsUnChecked(filterValues.items)}
                onChange={handleChangeFilter}
                selectAll={handleChangeAllFilters(true)}
                clearAll={handleChangeAllFilters(false)}
              />
            ))}
        </>
      );
    } else if (currentRoadMeasure) {
      return <InfoCard icon={getIconByDimension(currentRoadMeasure.columnName)} text={currentRoadMeasure.help} />;
    } else {
      return undefined;
    }
  };

  return (
    <>
      {Object.keys(roadMeasures || {}).length > 0 && (
        <Tabs selectedTabId={measure} onChange={handleTabChange}>
          {Object.values(roadMeasures || {})
            .filter((roadMeasure) =>
              isAnalysis
                ? roadMeasure.columnName !== MeasureType.PEDESTRIANS && roadMeasure.columnName !== MeasureType.TRUCKS
                : true,
            )
            .map((roadMeasure) => (
              <Tab
                key={roadMeasure.columnName}
                id={roadMeasure.columnName}
                disabled={timePeriod !== "2022" || disabled || loading}
                icon={<MeasureIcon measure={roadMeasure.columnName} fontSize="small" sx={{ marginRight: "4px" }} />}
                title={roadMeasure.label}
                panel={getPanelByMeasure()}
              />
            ))}
        </Tabs>
      )}
    </>
  );
};
