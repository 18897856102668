import styled from "@emotion/styled";
import { Box } from "@mui/material";
import mapboxgl, { Map } from "mapbox-gl";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MapBoxStyles } from "features/map/baseMapStyles";
import { CHOROPLETH_LINE_WIDTH } from "features/map/modules/od/map-data/od/layers";
import { getBounds, zoomOnArea } from "features/map/utils";

import { Button, FlexContainer, FocusAreaDropdown, MapErrorPage, Spinner } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";
import { globalActions } from "store/sections/global";

import { convertSquareKmToSquareMiles } from "utils/format";
import { reportAboutErrorState } from "utils/reports";

export interface FocusAreaPreviewProps {
  triggerGAEvent: (event: string) => void;
}

const MapLoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  margin-left: -25px;
  margin-top: -25px;
`;

const MapErrorContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const Mapbox = styled.div`
  width: 100%;
  height: 100%;
`;

const MapPreviewPanel = styled.div`
  position: relative;
  height: 100%;
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto min-content;
`;

const MapContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const DiagonalLabel = styled.div`
  position: absolute;
  padding: 5px 100px;
  transform: rotate(-45deg) translate(-37%, -50%);
  background: var(--color-green-400);
  z-index: 2;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  top: -20px;
`;

const InfoLabel = styled.div`
  display: flex;
  background: white;
  gap: 1rem;
  position: absolute;
  z-index: 2;
  font-size: 12px;
  top: 1rem;
  right: 1rem;
  padding: 0.3rem;
  border-radius: 4px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-grow: 1;
  direction: row;
  align-items: center;
  :last-child {
    flex-grow: 1;
  }
`;

const ModeControlsContainer = styled(FlexContainer)`
  flex-direction: row;
  border-radius: 0 0 8px 8px;
  padding: 1.5rem 1.5rem;
  background-color: var(--color-text);
  justify-content: space-between;
  & h4 {
    font-weight: 500;
  }
`;

const ModeControls = styled(FlexContainer)`
  & button {
    margin-left: 1rem;
  }
`;

export const FocusAreaPreview: FC<FocusAreaPreviewProps> = ({ triggerGAEvent }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);

  const [mapLoaded, setMapLoaded] = useState(false);

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);

  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);

  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const previousSelectedArea = usePrevious(selectedFocusArea);

  const error = useMemo(() => focusAreas.state === DataState.ERROR, [focusAreas.state]);

  const loading = useMemo(
    () =>
      focusAreas.state === DataState.LOADING ||
      ODMetadata.state === DataState.LOADING ||
      datasetMetadata.state === DataState.LOADING ||
      roadsMetadata.state === DataState.LOADING,
    [focusAreas.state, ODMetadata.state, datasetMetadata.state, roadsMetadata.state],
  );

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(analyticsActions.fetchFocusAreasAndDatasets());
    }
  }, [isTokenLoaded, dispatch]);

  useEffect(() => {
    if (focusAreas.data && selectedFocusArea && !map.current) {
      map.current = new mapboxgl.Map({
        accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
        container: mapContainer.current as HTMLElement,
        style: MapBoxStyles.Default,
        center: { lon: selectedFocusArea.centroidLon, lat: selectedFocusArea.centroidLat },
        zoom: 7,
        interactive: false,
      });

      map.current.on("load", () => {
        focusAreas.data?.forEach((area) => {
          map.current?.addSource(`entitledArea ${area.id}`, {
            type: "geojson",
            promoteId: "id",
            data: {
              type: "Feature",
              properties: {
                id: area.id,
                isDataset: Boolean(area.datasetId),
              },
              geometry: area.geometry,
            },
          });

          map.current?.addLayer({
            id: `area ${area.id}`,
            type: "fill",
            source: `entitledArea ${area.id}`,
            paint: {
              "fill-color": "#3b82f6",
              "fill-opacity": ["case", ["boolean", ["feature-state", "selected"], false], 0.2, 0],
            },
          });

          map.current?.addLayer({
            id: `boundaries ${area.id}`,
            type: "line",
            source: `entitledArea ${area.id}`,
            paint: {
              "line-color": "#0067b0",
              "line-width": CHOROPLETH_LINE_WIDTH,
            },
          });
        });

        setMapLoaded(true);
      });
    }
  }, [focusAreas.data, selectedFocusArea]);

  useEffect(() => {
    if (map.current && mapLoaded && selectedFocusArea) {
      if (previousSelectedArea) {
        map.current?.setFeatureState(
          {
            source: `entitledArea ${previousSelectedArea.id}`,
            id: previousSelectedArea.id,
          },
          {
            selected: false,
          },
        );
      }
      map.current?.setFeatureState(
        {
          source: `entitledArea ${selectedFocusArea.id}`,
          id: selectedFocusArea.id,
        },
        {
          selected: true,
        },
      );

      zoomOnArea(map.current, getBounds(selectedFocusArea.geometry));
    }
  }, [selectedFocusArea, previousSelectedArea, mapLoaded, searchParams, setSearchParams]);

  // Catching errors
  useEffect(() => {
    if (error) {
      reportAboutErrorState(
        {
          extraData: `Error statutes: focus areas: ${focusAreas.state === DataState.ERROR} (status: ${
            focusAreas.error?.status
          }, body: ${focusAreas.error?.body})`,
        },
        "The map failed to show the data on the dashboard page",
      );
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenMapPage = () => {
    triggerGAEvent("map-open");
    navigate("/map");
  };

  const handleChangeFocusArea = (focusAreaId: string) => {
    if (focusAreaId) {
      dispatch(globalActions.setSelectedFocusAreaId({ focusAreaId }));
    }
  };

  return (
    <MapPreviewPanel>
      <MapContainer>
        {selectedFocusArea?.isDemo ? <DiagonalLabel> Feature Preview </DiagonalLabel> : null}
        {selectedFocusArea ? (
          <InfoLabel>
            <Box>
              <b>Area:</b> {convertSquareKmToSquareMiles(selectedFocusArea.areaSqKm).toFixed(2)} sq/mi
            </Box>
            {selectedFocusArea?.population ? (
              <Box>
                <b>Pop:</b> {selectedFocusArea?.population?.toLocaleString("en-US") || ""}
              </Box>
            ) : undefined}
          </InfoLabel>
        ) : undefined}
        <Mapbox ref={mapContainer} style={{ visibility: error || loading ? "hidden" : "visible" }} />
        {error ? (
          <MapErrorContainer>
            <MapErrorPage size="sm" />
          </MapErrorContainer>
        ) : null}
        {loading ? (
          <MapLoadingContainer>
            <Spinner />
          </MapLoadingContainer>
        ) : null}
      </MapContainer>
      <ModeControlsContainer>
        <SelectContainer>
          <FocusAreaDropdown
            loading={loading}
            disabled={focusAreas.state === DataState.EMPTY || focusAreas.state === DataState.ERROR}
            options={focusAreas.data || []}
            value={selectedFocusArea}
            noLabel={true}
            onChange={handleChangeFocusArea}
            sx={{ width: "100%" }}
          />
        </SelectContainer>

        <ModeControls>
          <Button color="white" onClick={() => handleOpenMapPage()} disabled={error || loading}>
            Open
          </Button>
        </ModeControls>
      </ModeControlsContainer>
    </MapPreviewPanel>
  );
};
