import { SearchOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
  styled,
} from "@mui/material";
import { Map } from "mapbox-gl";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import { Button, MuiDialog, SelectorRoadClasses } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import {
  screenlinesActions,
  selectAreSuitableFieldCandidates,
  selectDescriptionFieldCandidates,
  selectIdFieldCandidates,
  selectNameFieldCandidates,
  selectScreenlineShapefileValidFeatures,
} from "store/sections/screenlines";

import { ImportScreenlinesPreviewMap } from "./ImportScreenlinesPreviewMap";
import { ImportScreenlinesTabs } from "./ImportScreenlinesTabs";
import { SmallTextField } from "./ScreenlineDetail";
import { UploadFiles } from "./UploadFiles";

const steps = ["Upload", "Import"];

const CustomStep = styled(Step)`
  .Mui-active svg {
    fill: ${({ theme }) => theme.palette.secondary.main};
  }
`;
export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
`;

export const StyledAlert = styled(Alert)`
  padding: 0 16px;
  height: 38px;
  min-width: 470px;
`;

const ValidationAlert = ({
  featuresLength,
  validFeaturesLength,
  disjointFeaturesCount,
}: {
  featuresLength: number;
  validFeaturesLength: number;
  disjointFeaturesCount: number;
}) => {
  const isPlural = featuresLength > 1;
  const screenlineMsg = `${isPlural ? "All" : ""} ${featuresLength} screenline${isPlural ? "s" : ""} ${
    isPlural ? "are" : "is"
  }`;

  if (validFeaturesLength === 0) {
    return <StyledAlert severity="error">{`${screenlineMsg} invalid and cannot be imported.`}</StyledAlert>;
  }
  if (validFeaturesLength === featuresLength && disjointFeaturesCount === 0) {
    return <StyledAlert severity="success">{`${screenlineMsg} valid and can be imported.`}</StyledAlert>;
  }
  return (
    <StyledAlert severity="warning">
      {`${validFeaturesLength} of ${featuresLength} screenlines are valid and can be imported.${
        disjointFeaturesCount ? ` ${disjointFeaturesCount} of them are outside of the selected area of interest.` : ""
      }`}
    </StyledAlert>
  );
};

export const ImportScreenlinesDialog: FC = () => {
  const dispatch = useAppDispatch();

  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const screenlines = useAppSelector((state) => state.screenlines.screenlines);

  const isImportScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isImportScreenlinesDialogOpen);
  const uploadedScreenlines = useAppSelector((state) => state.screenlines.uploadedScreenlines);
  const validFeatures = useAppSelector(selectScreenlineShapefileValidFeatures);
  const preparedFeatures = uploadedScreenlines.data?.preparedFeatures || [];
  const validationMessages = uploadedScreenlines.data?.validationMessages || [];
  const idFieldCandidates = useAppSelector(selectIdFieldCandidates);
  const nameFieldCandidates = useAppSelector(selectNameFieldCandidates);
  const descriptionFieldCandidates = useAppSelector(selectDescriptionFieldCandidates);
  const areSuitableFieldCandidates = useAppSelector(selectAreSuitableFieldCandidates);
  const disjointFeaturesCount = useAppSelector(
    (state) => state.screenlines.uploadedScreenlines.data?.disjointCount || 0,
  );

  const loading = uploadedScreenlines.state === DataState.LOADING;

  const [activeStep, setActiveStep] = useState(0);
  const [idField, setIdField] = useState("");
  const [nameField, setNameField] = useState("");
  const [descriptionField, setDescriptionField] = useState("");
  const [appendToExistingScreenlines, setAppendToExistingScreenlines] = useState(true);
  const [roadClasses, setRoadClasses] = useState<SelectorRoadClasses | null>(null);
  const [ignoreDisjointFeatures, setIgnoreDisjointFeatures] = useState(true);

  const map = useRef<Map | null>(null);

  const selectedRoadClasses = useMemo(
    () =>
      Object.values(roadClasses?.items || {})
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );

  const handleUploadScreenlines = (zippedShapefiles: Blob) => {
    dispatch(screenlinesActions.readScreenlineShapefile(zippedShapefiles));
  };

  const handleImportScreenlines = () => {
    if (timePeriod && validFeatures) {
      dispatch(
        screenlinesActions.convertFeaturesToScreenlines({
          timePeriod,
          roadClasses: selectedRoadClasses || [],
          idField,
          nameField,
          descriptionField,
          preparedFeatures: validFeatures,
          existingIds: appendToExistingScreenlines ? screenlines.map((s) => s.id) : [],
          ignoreDisjointFeatures,
        }),
      );
    }
  };

  const handleClose = () => {
    if (loading) return;
    dispatch(screenlinesActions.setIsImportScreenlinesDialogOpen(false));
  };

  useEffect(() => {
    if (uploadedScreenlines.state === DataState.AVAILABLE && activeStep === 0) {
      setActiveStep(1);
    }
  }, [uploadedScreenlines.state, activeStep]);

  useEffect(() => {
    if (uploadedScreenlines.data && activeStep === 1) {
      const { idField, nameField, descriptionField } = uploadedScreenlines.data;
      if (idField) setIdField(idField);
      if (nameField) setNameField(nameField);
      if (descriptionField) setDescriptionField(descriptionField);
    }
  }, [uploadedScreenlines.data, activeStep]);

  return (
    <MuiDialog onClose={handleClose} open={isImportScreenlinesDialogOpen}>
      <Typography variant="h2" style={{ textAlign: "center", marginTop: "20px", fontSize: "18px", fontWeight: "500" }}>
        Import screenlines
      </Typography>
      <DialogTitle>
        <Stepper activeStep={activeStep} style={{ width: "50%", margin: "0 auto" }} nonLinear>
          {steps.map((label, index) => (
            <CustomStep key={label} completed={true}>
              <StepButton disableRipple onClick={() => activeStep !== 1 && setActiveStep(index)}>
                {label}
              </StepButton>
            </CustomStep>
          ))}
        </Stepper>
      </DialogTitle>
      {activeStep === 0 && <UploadFiles uploadedFiles={uploadedScreenlines} uploadFiles={handleUploadScreenlines} />}
      {activeStep === 1 && (
        <>
          <DialogContent>
            <Grid container height={"100%"}>
              <Box sx={{ width: "42%", paddingRight: 2, height: "100%" }}>
                <Typography variant="subtitle2" textAlign={"left"}>
                  Fields with screenline attributes (optional)
                </Typography>

                {areSuitableFieldCandidates ? (
                  <Stack spacing={2} marginY={3}>
                    <SmallTextField
                      label="Id"
                      value={idField}
                      disabled={!idFieldCandidates?.length}
                      onChange={(e) => setIdField(e.target.value)}
                      select
                    >
                      {idFieldCandidates?.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </SmallTextField>
                    <SmallTextField
                      label="Name"
                      value={nameField}
                      disabled={!nameFieldCandidates?.length}
                      onChange={(e) => setNameField(e.target.value)}
                      select
                    >
                      {nameFieldCandidates?.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </SmallTextField>
                    <SmallTextField
                      label="Description"
                      value={descriptionField}
                      disabled={!descriptionFieldCandidates?.length}
                      onChange={(e) => setDescriptionField(e.target.value)}
                      select
                    >
                      {descriptionFieldCandidates?.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      ))}
                    </SmallTextField>
                  </Stack>
                ) : (
                  <Grid container justifyContent={"center"} alignItems={"center"} height={150} marginY={3}>
                    <SearchOff color="secondary" /> <Typography marginLeft={1}>No suitable field</Typography>
                  </Grid>
                )}
                <ImportScreenlinesTabs
                  map={map}
                  validationMessages={validationMessages}
                  preparedFeatures={preparedFeatures}
                  validFeatures={validFeatures}
                  roadClasses={roadClasses}
                  setRoadClasses={setRoadClasses}
                  appendToExistingScreenlines={appendToExistingScreenlines}
                  setAppendToExistingScreenlines={setAppendToExistingScreenlines}
                  ignoreDisjointFeatures={ignoreDisjointFeatures}
                  setIgnoreDisjointFeatures={setIgnoreDisjointFeatures}
                />
              </Box>
              <Box sx={{ width: "58%", height: "100%" }}>
                <ImportScreenlinesPreviewMap
                  map={map}
                  screenlinesFeatures={preparedFeatures || []}
                  validationMessages={validationMessages}
                />
              </Box>
            </Grid>
          </DialogContent>
          <StyledDialogActions>
            <ValidationAlert
              featuresLength={preparedFeatures?.length || 0}
              validFeaturesLength={validFeatures.length}
              disjointFeaturesCount={disjointFeaturesCount}
            />
            <Button
              color="primaryLight"
              loading={loading}
              disabled={
                !validFeatures.length ||
                loading ||
                (ignoreDisjointFeatures && validFeatures.length === disjointFeaturesCount)
              }
              onClick={handleImportScreenlines}
              style={{ width: "100px" }}
            >
              Import
            </Button>
          </StyledDialogActions>
        </>
      )}
    </MuiDialog>
  );
};
