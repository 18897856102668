import { Box, Typography } from "@mui/material";
import { FC } from "react";

export interface ScreenlinePlaceholderProps {
  isDrawScreenlineMode: boolean;
  screenlinesLength: number;
}

export const ScreenlinePlaceholder: FC<ScreenlinePlaceholderProps> = ({ isDrawScreenlineMode, screenlinesLength }) => {
  return (
    <Box paddingX={2} color={"text.secondary"}>
      {isDrawScreenlineMode ? (
        <>
          <Typography variant="subtitle2" color={"text.primary"} marginBottom={2} marginTop={1}>
            Drawing Screenlines
          </Typography>
          <Box component={"ol"} paddingLeft={2}>
            <Typography variant="body2" component={"li"} gutterBottom>
              To draw a line or cordon, single-click to add points. Double-click to finish.
            </Typography>
            <Typography variant="body2" component={"li"} gutterBottom>
              To edit the geometry, click on a vertex to select it. You can then drag or delete it.
            </Typography>
            <Typography variant="body2" component={"li"} gutterBottom>
              When you're finished, click "<strong>Confirm</strong>"" to finalize your changes.
            </Typography>
          </Box>
        </>
      ) : screenlinesLength === 0 ? (
        <>
          <Typography variant="subtitle2" color={"text.primary"} marginBottom={2} marginTop={1}>
            Defining Screenlines
          </Typography>
          <Typography variant="body2" marginBottom={2}>
            Screenlines sum up the volumes of intersecting road segments, by direction of crossing.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            Use "<strong>Add Screenline</strong>" to draw a new screenline on the map. This will add all intersections
            with the currently <strong>selected road classes</strong>.
          </Typography>

          <Typography variant="body2" marginBottom={2}>
            You can then <strong>edit</strong> the screenline to <strong>remove individual segment</strong>{" "}
            intersections in the list, or <strong>add candidate segments</strong> by selecting them on the map.
            Candidates can be shown by including more road classes in the filter.
          </Typography>
        </>
      ) : null}
    </Box>
  );
};
