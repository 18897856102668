import { CenterFocusStrong, Delete } from "@mui/icons-material";
import { IconButton, ListItem, ListItemButton, ListItemButtonProps, Stack, Switch, Typography } from "@mui/material";
import { FC, useRef } from "react";

export interface ScreenlineItemProps extends ListItemButtonProps {
  screenlineId: string;
  name: string | undefined;
  selected?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  visible?: boolean;
  handleZoomOnBounds: () => void;
  handleDeleteScreenline: () => void;
  handleToggleScreenlineVisibility: () => void;
}

export const ScreenlineItem: FC<ScreenlineItemProps> = ({
  screenlineId,
  name = "No name",
  selected,
  hovered,
  disabled,
  visible,
  onClick,
  handleZoomOnBounds,
  handleDeleteScreenline,
  handleToggleScreenlineVisibility,
  ...props
}) => {
  const ListItemButtonRef = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    handleZoomOnBounds();
  };

  return (
    <ListItem
      disablePadding
      disableGutters
      secondaryAction={
        <Stack direction="row" spacing={1} marginRight={2}>
          <Switch size="small" disabled={disabled} checked={visible} onChange={handleToggleScreenlineVisibility} />
          <IconButton edge="end" size="small" onClick={handleClick}>
            <CenterFocusStrong fontSize="inherit" />
          </IconButton>
          <IconButton edge="end" size="small" disabled={disabled} onClick={handleDeleteScreenline}>
            <Delete fontSize="inherit" />
          </IconButton>
        </Stack>
      }
    >
      <ListItemButton
        dense
        ref={ListItemButtonRef}
        color={"secondary"}
        selected={selected}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        <Typography fontSize={12} fontWeight={600} marginRight={1} minWidth={"40px"} maxWidth={"40px"} noWrap>
          {screenlineId}
        </Typography>
        <Typography fontSize={12} noWrap color={"text.secondary"}>
          {name || "No name"}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};
