import styled from "@emotion/styled";
import { FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent, FC } from "react";

import { ColorSchemeSelector } from "features/filters/ColorSchemeSelector";
import { sequentialSchemes } from "features/map/layerColors";

import { DividerWithText } from "components";

export interface MapLayersProps {
  showZones: boolean;
  colorScheme: string;
  changeShowZones: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  changeColorScheme: (scheme: string) => void;
}

const MapLayersWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RoadVmtMapLayers: FC<MapLayersProps> = ({
  showZones,
  colorScheme,
  changeShowZones,
  changeColorScheme,
}) => {
  return (
    <MapLayersWrapper>
      <DividerWithText>Map Layers</DividerWithText>
      <>
        <FormControlLabel
          control={
            <Switch sx={{ ml: 1 }} size="small" color="secondary" checked={showZones} onChange={changeShowZones} />
          }
          label="Road VMT Counts"
          sx={{ mb: 1 }}
        />
        <ColorSchemeSelector
          availableSchemes={sequentialSchemes}
          selectedColorScheme={colorScheme}
          onChange={changeColorScheme}
        />
      </>
    </MapLayersWrapper>
  );
};
