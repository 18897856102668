import { IconName } from "@blueprintjs/core";
import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import styled from "@emotion/styled";
import { Map } from "mapbox-gl";
import React, { FC, useMemo } from "react";

import { MAPBOX_STYLE_OPTIONS, MapBoxStyles } from "features/map/baseMapStyles";

import { DividerWithText, MenuItem, SelectInput } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { mapActions } from "store/sections/map";

interface BaseMapStylesProps {
  map: Map | null;
  disabled?: boolean;
}

const BaseMapStylesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const renderThemeItem: ItemRenderer<any> = (
  item: { label: string; value: any; icon: IconName },
  { handleClick, modifiers },
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      text={item.label}
      roleStructure="listoption"
      active={modifiers.active}
      key={item.label}
      onClick={handleClick}
      icon={item.icon}
    />
  );
};

export const BaseMapStyles: FC<BaseMapStylesProps> = ({ map, disabled }) => {
  const dispatch = useAppDispatch();
  const baseMapStyle = useAppSelector((state) => state.map.baseMapStyle);

  const activeItem = useMemo(() => MAPBOX_STYLE_OPTIONS.find((item) => item.value === baseMapStyle), [baseMapStyle]);

  const handleChangeBaseMapStyle = (style: MapBoxStyles) => {
    const bounds: any = map?.getBounds().toArray();

    if (bounds) {
      dispatch(mapActions.setMapBounds(bounds));
    }
    dispatch(mapActions.setBaseMapStyle(style));
  };

  return (
    <BaseMapStylesWrapper>
      <DividerWithText>Base Map Styles</DividerWithText>
      <Select
        items={MAPBOX_STYLE_OPTIONS}
        activeItem={activeItem}
        itemRenderer={renderThemeItem}
        filterable={false}
        onItemSelect={(theme) => {
          handleChangeBaseMapStyle(theme.value as MapBoxStyles);
        }}
      >
        <SelectInput value={activeItem?.label} icon={activeItem?.icon as IconName} disabled={disabled} />
      </Select>
    </BaseMapStylesWrapper>
  );
};
