import styled from "@emotion/styled";
import React from "react";

import { Button, Dialog, Input, TextArea } from "components";

import { ConfigDocument, ZoningItem } from "types";

interface Props {
  title: string;
  item: ZoningItem | ConfigDocument;
  onClose: () => void;
  onSubmit: (name: string, description: string) => void;
  errorMessage?: string | null;
}

const TitleText = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
`;

const Label = styled.label`
  font-size: 14px;
  margin: 10px 0;
  color: #2a2e33;
`;

const StyledTextArea = styled(TextArea)`
  resize: none;
`;

const SubmitButton = styled(Button)`
  margin-top: 24px;
`;

const ErrorMessage = styled.div`
  color: var(--color-red-800);
  font-size: 14px;
  margin-top: 8px;
`;

export const EditItemDialog = ({ title, item, errorMessage, onClose, onSubmit }: Props) => {
  const [name, setName] = React.useState(item.name);
  const [description, setDescription] = React.useState(item.description || "");

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit(name, description);
  };

  return (
    <Dialog onClose={onClose} isOpen>
      <TitleText>{title}</TitleText>
      <Label>Name</Label>
      <Input value={name} onChange={handleChangeName} />
      <Label>Description</Label>
      <StyledTextArea value={description} onChange={handleChangeDescription} />

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <SubmitButton color="primaryLight" disabled={!name} onClick={handleSubmit}>
        Update
      </SubmitButton>
    </Dialog>
  );
};
