import { Typography, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React from "react";

import { IconButton } from "components/atoms/icon-button/IconButton";

interface Props extends React.ComponentProps<typeof Dialog> {
  children: React.ReactNode;
  closeOnEscapeKeyDown?: boolean;
  closeOnBackdropClick?: boolean;
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 1200px;
    max-width: 1200px;
    border-radius: 8px;
  }

  &.zoning-info-dialog .MuiDialog-paper {
    min-height: 640px;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;
`;

const Title = styled(Typography)`
  display: block;
  position: absolute;
  top: 20px;
  left: 22px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const MuiDialog = ({
  children,
  closeOnEscapeKeyDown = false,
  closeOnBackdropClick = false,
  ...props
}: Props) => {
  return (
    <StyledDialog
      open={props.open}
      className={props.className}
      onClose={(e: any, reason) => {
        if (!closeOnEscapeKeyDown && reason === "escapeKeyDown") return;
        if (!closeOnBackdropClick && reason === "backdropClick") return;

        if (props.onClose) {
          props.onClose(e, reason);
        }
      }}
    >
      {props.title ? <Title>{props.title}</Title> : null}
      {props.onClose && (
        <StyledIconButton
          icon="cross"
          color="whiteShadow"
          variant="squared"
          size="xs"
          onClick={props.onClose as () => {}}
        />
      )}
      {children}
    </StyledDialog>
  );
};
