import { useAuth0 } from "@auth0/auth0-react";
import { ItemRenderer, Select2 as Select } from "@blueprintjs/select";
import { Chip, ChipProps, Divider, FormControlLabel, Stack, Switch, Typography, styled } from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";

import { buildFilters } from "features/filters/utils";

import {
  Button,
  Dialog,
  DialogProps,
  FlexContainer,
  Input,
  MenuItem,
  RoadClassSelector,
  SelectInput,
  SelectorRoadClasses,
  TextArea,
} from "components";

import { buildSegmentsPredicate, buildZonesPredicate } from "components/pages/analytics/select-link/utils";

import { useAppDispatch, useAppSelector } from "hooks";

import { exportActions } from "store/sections/export";
import { selectVisibleScreenlines } from "store/sections/screenlines";

import {
  AoiExportRequest,
  FocusAreaItem,
  MapVisualizationMode,
  MapVisualizationType,
  MeasureType,
  ODDatasetExportRequest,
  SelectLinkAoiExportConfig,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { FilterList, List, ListItem, getActiveFilters, getSelectLinkFilters } from "./";

export interface NewExportDialogProps extends DialogProps {
  mode: MapVisualizationMode | null;
  ODZoomLevel: string | undefined;
  measure: MeasureType;
  selectedArea: FocusAreaItem | null;
  isSelectLink: boolean;
  onClose: () => void;
}

const Label = styled("p")`
  margin-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
`;

const OptionsContainer = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  column-gap: 1rem;
`;

// reduce bottom padding due to the contained list having a bottom margin of 0.5rem
const ExportCard = styled("div")`
  height: calc(100% - 68px);
  min-height: 55px;
  max-height: 320px;
  overflow-y: auto;
  padding: 1rem 1rem 0.5rem 1rem;
  margin-bottom: 8px;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
`;

const ExportContentCard = styled(ExportCard)<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const FiltersContainer = styled("div")`
  margin-top: 8px;
`;

const ModuleHeader = styled(Divider)(({ theme }) => ({
  fontWeight: 600,
  marginTop: theme.spacing(2),
}));

const MeasureChip = styled((props: ChipProps) => <Chip size="small" variant="outlined" {...props} />)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
  fontSize: "12px",
}));

const getMeasureLabel = (measure: MeasureType) => {
  switch (measure) {
    case MeasureType.AADT:
      return "Vehicles";
    case MeasureType.TRUCKS:
      return "Truck only";
    case MeasureType.PEDESTRIANS:
      return "Pedestrians";
    default:
      return "Vehicles";
  }
};

export const NewExportDialog: FC<NewExportDialogProps> = ({
  mode,
  ODZoomLevel,
  measure,
  selectedArea,
  isSelectLink,
  onClose,
  ...props
}) => {
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const dispatch = useAppDispatch();

  const [exportDescription, setExportDescription] = useState("");
  const [exportZoneLevel, setExportZoneLevel] = useState(ODZoomLevel);
  const [roadClasses, setRoadClasses] = useState<SelectorRoadClasses | null>(null);

  const numZones = useAppSelector((state) => state.export.numZones);
  const currentODFilters = useAppSelector((state) => state.filters.ODFilters);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const currentDatasetFilters = useAppSelector((state) => state.filters.datasetFilters);
  const ODMetadata = useAppSelector((state) => state.analytics.ODMetadata);
  const roadsMetadata = useAppSelector((state) => state.analytics.roadsMetadata);
  const datasetMetadata = useAppSelector((state) => state.analytics.datasetMetadata);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const visibleScreenlines = useAppSelector(selectVisibleScreenlines);
  const savedRoadClasses = useAppSelector((state) => state.filters.roadClasses);

  const [exportOptions, setExportOptions] = useState({
    shouldExportVolumeBreakdowns: true,
    shouldExportScreenlines: visibleScreenlines.length > 0,
  });

  const selectLinkConfig = useAppSelector((state) => state.selectLink.selectLinkConfig);
  const selectLinkExportConfig: SelectLinkAoiExportConfig | null = useMemo(
    () =>
      selectLinkConfig && selectLinkConfig.data
        ? {
            selectedSegmentsPredicate: buildSegmentsPredicate(
              selectLinkConfig.data.segmentsGroups,
              selectLinkConfig.data.segmentsGroupsOp,
            ),
            selectedOriginsPredicate: buildZonesPredicate(selectLinkConfig.data.origins),
            selectedDestinationsPredicate: buildZonesPredicate(selectLinkConfig.data.destinations),
            countsFilter: {
              min: selectLinkConfig.data.minCount,
              max: 1e9,
            },
          }
        : null,
    [selectLinkConfig],
  );

  const selectedFocusArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  // Determine height of the export content card. The calculated height allows for all selectable options without excessive
  // whitespace at the end, while making sure that no scrollbar is shown.
  // Note that the list length changes based on options (screenlines exported or not), so the height cannot be automatic,
  // otherwise the dialog would resize when the user toggles the screenlines option.
  const exportContentHeight = (visibleScreenlines.length > 0 ? 30 : 0) + (selectedFocusArea?.datasetId ? 140 : 110);

  const datasetZoningLevels = useMemo(() => {
    if (datasetMetadata.data?.zoningLevels) {
      return selectedFocusArea?.zoningLevel === "Custom" && ODMetadata.data?.zoningLevels?.[0]
        ? [...datasetMetadata.data.zoningLevels, ODMetadata.data?.zoningLevels?.[0]]
        : datasetMetadata.data.zoningLevels;
    }
  }, [ODMetadata.data?.zoningLevels, datasetMetadata.data?.zoningLevels, selectedFocusArea?.zoningLevel]);

  const zoningLevels = useMemo(
    () => (selectedFocusArea?.datasetId ? datasetZoningLevels : ODMetadata.data?.zoningLevels),
    [selectedFocusArea?.datasetId, datasetZoningLevels, ODMetadata.data?.zoningLevels],
  );

  const selectedRoadClasses = useMemo(
    () =>
      Object.values(roadClasses?.items || {})
        .filter(({ isChecked }) => isChecked)
        .map(({ value }) => Number(value)),
    [roadClasses],
  );

  const isExportPermitted =
    mode === MapVisualizationType.OD
      ? selectedArea?.datasetId
        ? datasetMetadata.data?.exportPermissions.allowExport
        : ODMetadata.data?.exportPermissions?.allowExport
      : roadsMetadata.data?.exportPermissions?.allowExport;

  const isODDisabled = useMemo(() => ODMetadata.error?.status === 403, [ODMetadata.error?.status]);

  const isDatasetDisabled = useMemo(() => datasetMetadata.error?.status === 403, [datasetMetadata.error?.status]);

  const isRoadsDisabled = useMemo(() => roadsMetadata.error?.status === 403, [roadsMetadata.error?.status]);

  const currentRoadMeasure = roadsMetadata.data?.measures.find((m) => m.columnName === measure);

  const getZoneLevelDisplayName = (zoneLevelId: string = exportZoneLevel as string) => {
    return (zoningLevels || []).find(({ id }) => id === zoneLevelId)?.longNameSingular;
  };

  const renderNumZone: ItemRenderer<any> = (item: { level: string; num: number }, { handleClick, modifiers }) => {
    const zoneLevelName = getZoneLevelDisplayName(item.level);
    return zoneLevelName ? (
      <MenuItem
        text={`${zoneLevelName} (${item.num})`}
        roleStructure="listoption"
        active={modifiers.active}
        key={item.level}
        onClick={handleClick}
      />
    ) : null;
  };

  const getExportNumZones = () => {
    return numZones.data?.find(({ level }) => level === exportZoneLevel)?.num || 0;
  };

  const onNewExport = () => {
    if (selectedArea && focusAreas.data && timePeriod) {
      const aoiExportRequest: AoiExportRequest = {
        exportDescription,
        areaOfInterestName: selectedArea.region,
        timePeriod,
        areaOfInterest: selectedArea?.areas || [],
        shouldGenerateSeqIds: true,
        ...(isODDisabled || isSelectLink
          ? {}
          : {
              od: {
                measures: {
                  aadt: {
                    filter: buildFilters(currentODFilters),
                  },
                },
                level: exportZoneLevel,
                shouldExportZones: true,
              },
            }),
        ...(isRoadsDisabled
          ? {}
          : {
              road: {
                measures: {
                  [measure]: {
                    filter: buildFilters(currentRoadFilters),
                  },
                },
                shouldExportSegments: true,
                shouldExportVolumeBreakdowns: exportOptions.shouldExportVolumeBreakdowns,
                shouldExportVolumes: !isSelectLink,
                roadClassesToExport: isSelectLink ? null : selectedRoadClasses,
                screenlinesToExport: exportOptions.shouldExportScreenlines ? visibleScreenlines : null,
              },
            }),
        ...(isSelectLink && selectLinkExportConfig
          ? {
              selectLink: selectLinkExportConfig,
            }
          : {}),
      };

      const datasetExportRequest: ODDatasetExportRequest = {
        exportDescription,
        areaOfInterestName: selectedArea.region,
        measure: "aadt",
        level: exportZoneLevel,
        ...(isDatasetDisabled ? {} : { filter: buildFilters(currentDatasetFilters) }),
        ...(isRoadsDisabled ? {} : { roadFilter: buildFilters(currentRoadFilters) }),
        areaOfInterest: selectedArea?.areas || null,
        compression: "gzip",
        shouldExportZones: true,
        shouldGenerateSeqIds: true,
        shouldExportVolumeBreakdowns: exportOptions.shouldExportVolumeBreakdowns,
        shouldExportOdBreakdowns: true,
        roadClassesToExport: selectedRoadClasses,
        roadMeasure: measure,
        screenlinesToExport: exportOptions.shouldExportScreenlines ? visibleScreenlines : null,
      };

      if (selectedArea.datasetId) {
        addCustomGAEvent("new_export", "start_new_export", "dataset_export", user, userOrganizationName);
        dispatch(exportActions.addDatasetExportJob(selectedArea.datasetId, datasetExportRequest));
      } else {
        addCustomGAEvent("new_export", "start_new_export", "aoi_export", user, userOrganizationName);
        dispatch(exportActions.addAOIExportJob(aoiExportRequest));
      }

      onClose();
    }
  };

  const handleChangeOptions = (event: ChangeEvent<HTMLInputElement>) => {
    setExportOptions({
      ...exportOptions,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (timePeriod && !isODDisabled) {
      dispatch(
        exportActions.fetchNumZones({
          timePeriod,
          areaOfInterest: selectedArea?.areas || null,
          datasetId: selectedArea?.datasetId,
        }),
      );
    }
  }, [selectedArea?.areas, selectedArea?.datasetId, isODDisabled, timePeriod, dispatch]);

  return (
    <Dialog onClose={onClose} {...props}>
      <h2 style={{ margin: "-2rem 0 1rem 0" }}>New Export</h2>
      {/* fix card height so that all currently supported elements can fit without changing dialog size or scrolling: */}
      <ExportContentCard height={exportContentHeight}>
        <Label>Export will include:</Label>
        <List>
          {!isSelectLink && (
            <>
              <ListItem>OD data (csv)</ListItem>
              <ListItem>OD zones (shapefile)</ListItem>
            </>
          )}
          <ListItem>Road volumes (csv)</ListItem>
          <ListItem>
            {exportOptions.shouldExportVolumeBreakdowns && !isSelectLink
              ? "Road network with detailed volumes by category (shapefile)"
              : "Road network with total volumes (shapefile)"}
          </ListItem>
          {exportOptions.shouldExportScreenlines && (
            <>
              <ListItem>Screenlines (shapefile)</ListItem>
              <ListItem>Screenline segments (csv)</ListItem>
            </>
          )}
          {selectedArea?.datasetId && (
            <>
              <ListItem>Subarea (shapefile)</ListItem>
              <ListItem>Gates (csv)</ListItem>
            </>
          )}
          {isSelectLink && (
            <>
              <ListItem>Select link analysis road volumes (csv)</ListItem>
              <ListItem>Select link analysis od data (csv)</ListItem>
              <ListItem>OD zones (shapefile)</ListItem>
            </>
          )}
        </List>
      </ExportContentCard>
      <Label>Export Description</Label>
      <TextArea
        id="exportDescription"
        value={exportDescription}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setExportDescription(e.target.value)}
      />

      {isSelectLink && <div style={{ marginBottom: "1rem" }} />}

      <OptionsContainer>
        <ModuleHeader>{isSelectLink ? "Select Link" : "OD"}</ModuleHeader>
        <ModuleHeader>Road</ModuleHeader>

        {!isSelectLink && (
          <>
            <MeasureChip label={getMeasureLabel(MeasureType.AADT)} />
            <MeasureChip label={getMeasureLabel(currentRoadMeasure?.columnName as MeasureType)} />
            <div style={{ marginTop: "8px" }}>
              <Label>Zoning level</Label>
              {!isODDisabled && !isDatasetDisabled ? (
                <Select
                  items={numZones.data || []}
                  itemRenderer={renderNumZone}
                  activeItem={{ level: exportZoneLevel, num: getExportNumZones() }}
                  onItemSelect={({ level }) => setExportZoneLevel(level)}
                  filterable={false}
                  popoverProps={{
                    matchTargetWidth: true,
                    minimal: true,
                  }}
                  fill
                >
                  <SelectInput value={`${getZoneLevelDisplayName()} (${getExportNumZones()})`} />
                </Select>
              ) : (
                <Input disabled />
              )}
            </div>

            <RoadClassSelector
              roadClasses={roadClasses}
              savedRoadClasses={savedRoadClasses}
              setRoadClasses={setRoadClasses}
            />

            <div />

            <Stack>
              {/* arrange the options vertically */}
              <Label>Options</Label>
              <FormControlLabel
                sx={{ ml: 0 }}
                control={
                  <Switch
                    size="small"
                    color="secondary"
                    name="shouldExportVolumeBreakdowns"
                    checked={exportOptions.shouldExportVolumeBreakdowns}
                    onChange={handleChangeOptions}
                  />
                }
                label={
                  <Typography fontSize={12} maxWidth={220} ml={1}>
                    Road network with detailed volumes by category in shapefile
                  </Typography>
                }
              />
              {visibleScreenlines.length > 0 && (
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Switch
                      size="small"
                      color="secondary"
                      name="shouldExportScreenlines"
                      checked={exportOptions.shouldExportScreenlines}
                      onChange={handleChangeOptions}
                    />
                  }
                  label={
                    <Typography fontSize={12} maxWidth={220} ml={1}>
                      Screenlines and screenline segments
                    </Typography>
                  }
                />
              )}
            </Stack>
          </>
        )}

        {isSelectLink && selectLinkExportConfig ? (
          <FiltersContainer>
            <Label>Filters</Label>
            <ExportCard>
              <FilterList filters={getSelectLinkFilters(selectLinkExportConfig)} />
            </ExportCard>
          </FiltersContainer>
        ) : (
          <FiltersContainer>
            <Label>Filters</Label>
            <ExportCard>
              <FilterList
                filters={
                  selectedArea?.datasetId ? getActiveFilters(currentDatasetFilters) : getActiveFilters(currentODFilters)
                }
              />
            </ExportCard>
          </FiltersContainer>
        )}

        <FiltersContainer>
          <Label>Filters</Label>
          <ExportCard>
            <FilterList filters={getActiveFilters(currentRoadFilters)} />
          </ExportCard>
        </FiltersContainer>
      </OptionsContainer>
      <SpaceBetweenContainer>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!isExportPermitted} onClick={onNewExport}>
          Start export
        </Button>
      </SpaceBetweenContainer>
    </Dialog>
  );
};
