import { useAuth0 } from "@auth0/auth0-react";
import { IconName } from "@blueprintjs/core";
import styled from "@emotion/styled";
import { isEqual } from "lodash";
import React, { ChangeEvent, FC, useDeferredValue, useEffect, useMemo, useState } from "react";

import { areAllItemsUnChecked } from "features/filters/Filters";

import { Button, CheckboxDropdownItem, DividerWithText, FlexContainer } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { corridorActions } from "store/sections/corridor";

import { Measure, MeasureType } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { FiltersPanel } from "./FiltersPanel";

interface Filter extends CheckboxDropdownItem {
  value: string;
}

type FilterItems = {
  [key: string]: Filter;
};

interface FilterGroup extends CheckboxDropdownItem {
  items: FilterItems;
  icon: IconName;
}

export type FiltersType = {
  [key: string]: FilterGroup;
};

export interface FiltersProps {
  loading: boolean;
}

const FormWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 46px;
`;

const FiltersControls = styled(FlexContainer)`
  margin-top: 0.5rem;
  justify-content: space-between;
`;

const getFiltersByMeasure = (measure: Measure): FiltersType => {
  const newFilters: FiltersType = {};
  if (measure) {
    for (let i = 0, { length } = measure.dimensions; i < length; i++) {
      const dimension = measure.dimensions[i];
      if (dimension.enabled) {
        newFilters[dimension.columnName] = {
          isChecked: true,
          label: dimension.label,
          icon: getIconByDimension(dimension.columnName),
          items: Object.entries(dimension.categories || {}).reduce((newItems: FilterItems, [itemKey, itemValue]) => {
            const item = itemValue as any;
            newItems[itemKey] = {
              ...item,
              label: item.label || item.value,
              isChecked: true,
            };
            return newItems;
          }, {}),
        };
      }
    }
  }

  return newFilters;
};

export const getIconByDimension = (dimension: string) => {
  switch (dimension) {
    case "day_type":
      return "calendar";
    case "hour":
      return "time";
    default:
      return "filter";
  }
};

export const Filters: FC<FiltersProps> = ({ loading }) => {
  const dispatch = useAppDispatch();
  const { user } = useAuth0();

  const [filters, setFilters] = useState<FiltersType | null>(null);
  const deferredFilters = useDeferredValue(filters);

  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const corridorMetadata = useAppSelector((state) => state.corridor.corridorMetadata);
  const currentFilters = useAppSelector((state) => state.corridor.filters);

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const currentCurrentMeasure = useMemo(
    () => corridorMetadata.data?.measures.filter((m) => m.columnName === MeasureType.AADT)[0],
    [corridorMetadata.data?.measures],
  );
  const isRevertFiltersOn = useMemo(() => filters && !isEqual(filters, currentFilters), [currentFilters, filters]);
  const areInvalidFilters = useMemo(() => {
    if (filters) {
      return Object.values(filters).some((filter) => areAllItemsUnChecked(filter.items));
    }

    return false;
  }, [filters]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (filters) {
      const namesArr = event.target.name.split("-");
      const groupName = namesArr[0];
      const itemName = namesArr[1];

      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: Object.entries(filters[groupName].items).every(([itemKey, item]) => {
            return itemKey === itemName ? !filters[groupName].items[itemName].isChecked : item.isChecked;
          }),
          items: {
            ...filters[groupName].items,
            [itemName]: {
              ...filters[groupName].items[itemName],
              isChecked: !filters[groupName].items[itemName].isChecked,
            },
          },
        },
      });
    }
  };

  const handleChangeAllFilters = (isChecked: boolean) => (groupName: string) => {
    if (filters) {
      setFilters({
        ...filters,
        [groupName]: {
          ...filters[groupName],
          isChecked: isChecked,
          items: Object.entries(filters[groupName].items).reduce((newItems, [itemKey, itemValue]) => {
            newItems[itemKey] = {
              ...itemValue,
              isChecked: isChecked,
            };
            return newItems;
          }, {} as FilterItems),
        },
      });
    }
  };

  const handleSubmit = () => {
    if (filters && selectedFocusArea) {
      if (corridorMetadata.state === DataState.AVAILABLE && timePeriod) {
        addCustomGAEvent("corridor", "filters", "change", user, userOrganizationName);
        dispatch(corridorActions.updateCurrentFilters(filters));
      }
    }
  };

  const handleRevertFilters = () => {
    setFilters(currentFilters);
  };

  //Get corridor filters set redux state
  useEffect(() => {
    if (!currentFilters && currentCurrentMeasure) {
      const newFilters: FiltersType = getFiltersByMeasure(currentCurrentMeasure);
      dispatch(corridorActions.updateCurrentFilters(newFilters));
    }
  }, [selectedFocusArea?.datasetId, currentFilters, currentCurrentMeasure, dispatch]);

  //Set filters on first render
  useEffect(() => {
    if (
      corridorMetadata.state === DataState.AVAILABLE &&
      currentFilters &&
      !filters &&
      selectedFocusArea &&
      !selectedFocusArea.datasetId
    ) {
      setFilters(currentFilters);
    }
  }, [filters, currentFilters, selectedFocusArea, corridorMetadata]);

  useEffect(() => {
    if (selectedFocusArea) {
      setFilters(null);
    }
  }, [selectedFocusArea, timePeriod]);

  return (
    <FormWrapper>
      <div>
        <DividerWithText>Filters</DividerWithText>
        <FiltersPanel
          filters={deferredFilters}
          loading={loading}
          handleChangeFilter={handleChangeFilter}
          handleChangeAllFilters={handleChangeAllFilters}
        />
      </div>

      <FiltersControls>
        {isRevertFiltersOn ? (
          <Button color="white" size="sm" onClick={handleRevertFilters}>
            Revert
          </Button>
        ) : (
          <div />
        )}

        <Button size="sm" type="submit" disabled={areInvalidFilters || !isRevertFiltersOn} onClick={handleSubmit}>
          Apply
        </Button>
      </FiltersControls>
    </FormWrapper>
  );
};
