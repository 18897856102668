import { EditableText, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 as Popover } from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import { DateRange } from "@mui/icons-material";
import { CircularProgress, IconButton as MuiIconButton, Tooltip, Typography } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { isEqual } from "lodash";
import React, { Dispatch, FC, SetStateAction, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { SaveErrorDialog } from "features/dataset-editor";

import {
  AreaName,
  Badge,
  BlueIcon,
  Button,
  ConfirmDialog,
  Divider,
  FlexContainer,
  IconButton,
  RoadClassSelector,
  SelectorRoadClasses,
  Switch,
  TextArea,
} from "components";

import { useAppDispatch, useAppSelector, usePrompt } from "hooks";

import { DataState } from "store/interfaces";
import { analyticsActions } from "store/sections/analytics";

import { themeColors } from "theme/themeConstants";

import { FocusAreaItem } from "types";

import { ZoningSelector } from "./ZoningSelector";

const LoaderContainer = styled(FlexContainer)`
  height: 100%;
  justify-content: center;
`;

const FormWrapper = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FieldsContainer = styled.div`
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  margin-right: -1rem;
`;

const DatasetName = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-top: 0.5rem;
  & .bp4-editable-text-content,
  .bp4-editable-text-input {
    width: 210px !important;
    padding-right: 0.5rem;
  }
`;

const AreaNameContainer = styled.div`
  margin: 0.75rem 0;
  display: flex;
`;

const DrawControlsWrapper = styled.div`
  margin: 1rem 0;
`;

const DrawControls = styled(FlexContainer)`
  justify-content: space-around;
  background: var(--color-text-field-gray);
  border-radius: 8px;
  padding: 15px 5px;
  margin-top: 0.5rem;
`;

const DrawIconButton = styled(MuiIconButton)<{ selected?: boolean }>`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: ${({ selected }) => (selected ? "#000000" : "#ffffff")};
  box-shadow: var(--box-shadow-md);
  border: 1px solid var(--color-border);

  & svg {
    height: 14px;
    width: 14px;
    color: #000;
    color: ${({ selected }) => (selected ? "#ffffff" : "#000000")};
  }
`;

const Description = styled(TextArea)`
  width: 100%;
  max-height: 120px;
  background: var(--color-text-field-gray) !important;
  border-radius: 8px;
  border: none !important;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-textSecondary);
  resize: none;
  margin: 0.5rem 0;
`;

const ActionButtons = styled(FlexContainer)`
  margin: 1rem 0;
  justify-content: space-between;
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin: 1rem 0;
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 600;
`;

const MapLayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 1rem;
`;

const ComputeButton = styled(Button)`
  width: 100px;
`;

export interface DatasetForm {
  datasetName: string;
  description: string;
}

export interface MainPanelProps {
  draw: any;
  zoningOptions: string[];
  selectedZoning: string | undefined;
  showZones: boolean;
  showRoads: boolean;
  showGates: boolean;
  roadClasses: SelectorRoadClasses | null;
  areaOfInterest: FocusAreaItem | undefined;
  setSelectedZoning: (zoning: string, customZoningId?: string) => void;
  setPolygonSubtractMode: (isSubtractMode: boolean) => void;
  toggleGateEditorPanel: () => void;
  changeShowZones: () => void;
  changeShowRoads: () => void;
  changeShowGates: () => void;
  setRoadClasses: Dispatch<SetStateAction<SelectorRoadClasses | null>>;
  onAddFullEntireAreaPolygon: () => void;
  openValidationModal: () => void;
  setDrawModeActive: (isActive: boolean) => void;
  isDrawModeActive: boolean;
  isPolygonSelected: boolean;
  isPolygonSubtractMode: boolean;
}

export const MainPanel: FC<MainPanelProps> = memo(
  ({
    draw,
    zoningOptions,
    selectedZoning,
    showZones,
    showRoads,
    showGates,
    roadClasses,
    areaOfInterest,
    setSelectedZoning,
    setPolygonSubtractMode,
    toggleGateEditorPanel,
    changeShowZones,
    changeShowRoads,
    changeShowGates,
    setRoadClasses,
    onAddFullEntireAreaPolygon,
    openValidationModal,
    setDrawModeActive,
    isDrawModeActive,
    isPolygonSelected,
    isPolygonSubtractMode,
  }) => {
    const dispatch = useAppDispatch();
    const { datasetId } = useParams();

    const [isUnsavedChangesBeforeCompute, setIsUnsavedChangesBeforeCompute] = useState(false);
    const [isNeedToOpenComputeModalAfterSaving, setIsNeedToOpenComputeModalAfterSaving] = useState(false);
    const [isSaveErrorModalOpen, setIsSaveErrorModalOpen] = useState(false);

    const subareaState = useAppSelector((state) => state.analytics.subareaState);
    const ODDatasetConfiguration = useAppSelector((state) => state.analytics.ODDatasetConfig);
    const initialODDatasetConfiguration = useAppSelector((state) => state.analytics.savedODDatasetConfig);
    const ODDatasetConfigValidation = useAppSelector((state) => state.analytics.ODDatasetConfigValidation);
    const customZoningId = useAppSelector((state) => state.analytics.ODDatasetConfig.data?.customZoningId);
    const timePeriod = useAppSelector((state) => state.global.timePeriod);
    const loadingSubAreaPolygon = useAppSelector((state) => state.analytics.loadingSubareaPolygon);
    const loadingGenerateGates = useAppSelector((state) => state.analytics.loadingGeneratedGates);
    const addGateState = useAppSelector((state) => state.analytics.newGate.state);

    useEffect(() => {
      setIsSaveErrorModalOpen(Boolean(ODDatasetConfiguration.error));
    }, [ODDatasetConfiguration.error]);

    useEffect(() => {
      if (ODDatasetConfiguration.state === DataState.AVAILABLE && isNeedToOpenComputeModalAfterSaving) {
        setIsNeedToOpenComputeModalAfterSaving(false);
        openValidationModal();
      }
    }, [ODDatasetConfiguration.state, isNeedToOpenComputeModalAfterSaving, openValidationModal]);

    const handleSubmit = (
      values: DatasetForm,
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
      overwrite?: boolean,
    ) => {
      if (ODDatasetConfiguration.data && selectedZoning) {
        const { gates, subAreaGeometry, timePeriod, gateRoadClasses, licensedAreaId } = ODDatasetConfiguration.data;
        dispatch(
          analyticsActions.updateODDatasetConfig(ODDatasetConfiguration.data.datasetId, {
            datasetName: values.datasetName,
            gates,
            subAreaGeometry,
            description: values.description,
            timePeriod,
            gateRoadClasses,
            licensedAreaId,
            zoningLevel: selectedZoning,
            customZoningId,
            ...(!overwrite && {
              expectedVersion: ODDatasetConfiguration.data.version,
            }),
          }),
        );
        setSubmitting(false);
      }
    };

    const formik = useFormik<DatasetForm>({
      enableReinitialize: true,
      initialValues: {
        datasetName: ODDatasetConfiguration.data?.datasetName || "",
        description: ODDatasetConfiguration.data?.description || "",
      },
      onSubmit: handleSubmit,
    });

    const { values, initialValues, errors, isSubmitting, touched, dirty, setFieldValue, setSubmitting, handleReset } =
      formik;

    const areChangesPending = useMemo(() => {
      if (ODDatasetConfiguration.data && initialValues.datasetName && !dirty) {
        const currentODConfiguration = {
          ...ODDatasetConfiguration.data,
          datasetName: values.datasetName,
          ...(values.description && { description: values.description }),
          zoningLevel: selectedZoning,
          ...(customZoningId ? { customZoningId } : {}),
        };
        return !isEqual(initialODDatasetConfiguration, currentODConfiguration);
      }
      return false;
    }, [
      ODDatasetConfiguration.data,
      initialODDatasetConfiguration,
      selectedZoning,
      customZoningId,
      values.datasetName,
      values.description,
      dirty,
      initialValues.datasetName,
    ]);

    usePrompt("Changes that you made may not be saved.", areChangesPending);

    const handleCreateNewDataset = (datasetName: string, description: string) => {
      if (ODDatasetConfiguration.data) {
        const { folderId, timePeriod, zoningLevel, gateRoadClasses, gates, subAreaGeometry } =
          ODDatasetConfiguration.data;

        dispatch(
          analyticsActions.createODDatasetConfig({
            index: 0,
            folderId,
            datasetName,
            description,
            timePeriod,
            zoningLevel,
            gateRoadClasses,
            gates,
            subAreaGeometry,
          }),
        );
      }
    };

    const handleRevertChanges = useCallback(
      (resetForm: (e: any) => void) => {
        if (initialODDatasetConfiguration) {
          resetForm({});

          dispatch(analyticsActions.updateODDatasetConfigSucceeded(initialODDatasetConfiguration));
        }
      },
      [initialODDatasetConfiguration, dispatch],
    );

    const handleDiscardChanges = useCallback(() => {
      if (datasetId) {
        dispatch(analyticsActions.fetchODDatasetConfig(datasetId));
      }
    }, [datasetId, dispatch]);

    const handleComputeSubmit = () => {
      if (areChangesPending) {
        setIsUnsavedChangesBeforeCompute(true);
      } else {
        openValidationModal();
      }
    };

    const handleCloseConfirmSaveBeforeCompute = useCallback(() => {
      if (isUnsavedChangesBeforeCompute) {
        setIsUnsavedChangesBeforeCompute(false);
      }
    }, [isUnsavedChangesBeforeCompute]);

    const handleConfirmSaveBeforeComputeSubmit = (values: DatasetForm, formikHelpers: FormikHelpers<DatasetForm>) => {
      handleSubmit(values, formikHelpers);
      setIsUnsavedChangesBeforeCompute(false);
      setIsNeedToOpenComputeModalAfterSaving(true);
    };

    const handleDrawMode = () => {
      if (isDrawModeActive) {
        setDrawModeActive(false);
        draw.changeMode("simple_select");
      } else {
        setPolygonSubtractMode(false);
        setDrawModeActive(true);
        draw.changeMode("draw_polygon");
      }
    };

    const handleSubtractMode = () => {
      if (isPolygonSubtractMode) {
        setPolygonSubtractMode(false);
        draw.changeMode("simple_select");
      } else {
        setDrawModeActive(false);
        setPolygonSubtractMode(true);
        draw.changeMode("draw_polygon");
      }
    };

    const loading =
      isSubmitting ||
      loadingSubAreaPolygon ||
      loadingGenerateGates ||
      addGateState === DataState.LOADING ||
      ODDatasetConfiguration.state === DataState.LOADING ||
      subareaState.state === DataState.LOADING ||
      ODDatasetConfigValidation.state === DataState.LOADING;

    return (
      <>
        {isSaveErrorModalOpen && (
          <SaveErrorDialog
            error={ODDatasetConfiguration.error as any}
            isOpen={isSaveErrorModalOpen}
            handleDiscardChanges={handleDiscardChanges}
            handleOverwrite={() => handleSubmit(values, { setSubmitting }, true)}
            handleCreateNewDataset={(datasetName: string) => handleCreateNewDataset(datasetName, values.description)}
            onClose={() => setIsSaveErrorModalOpen(false)}
          />
        )}

        {ODDatasetConfiguration.state === DataState.LOADING ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <FormWrapper onSubmit={formik.handleSubmit}>
            <FieldsContainer>
              <DatasetName>
                <BlueIcon icon={IconNames.DOCUMENT} style={{ marginTop: "2px" }} />
                <EditableText
                  multiline
                  maxLines={4}
                  placeholder="New Project"
                  value={values.datasetName}
                  disabled={isSubmitting}
                  onChange={(newVal) => setFieldValue("datasetName", newVal)}
                />
                {errors.datasetName && <div className="error">{errors.datasetName}</div>}
              </DatasetName>
              <AreaNameContainer>
                <BlueIcon icon="area-of-interest" />
                <AreaName>{areaOfInterest?.region}</AreaName>
              </AreaNameContainer>
              <AreaNameContainer>
                <DateRange color={"secondary"} sx={{ fontSize: "16px", marginRight: "4px" }} />
                <Typography variant="body2" fontWeight={500} color={`${themeColors.textSecondary}`}>
                  {timePeriod}
                </Typography>
              </AreaNameContainer>

              <Description
                placeholder="Dataset description..."
                rows={3}
                growVertically
                fill
                value={values.description}
                onChange={(e) => setFieldValue("description", e.target.value)}
              />

              <Divider />

              <DrawControlsWrapper>
                <Label>Define Dataset Subarea</Label>
                <DrawControls>
                  <Tooltip
                    title={
                      ODDatasetConfiguration.data?.subAreaGeometry
                        ? "Draw polygon to extend subarea"
                        : "Draw polygon to define subarea"
                    }
                  >
                    <span>
                      <DrawIconButton disableRipple selected={isDrawModeActive} size="small" onClick={handleDrawMode}>
                        <Icon icon="polygon-filter" />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Cover entire area">
                    <span>
                      <DrawIconButton
                        disableRipple
                        color="default"
                        size="small"
                        onClick={() => onAddFullEntireAreaPolygon()}
                      >
                        <Icon icon="area-of-interest" />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title="Draw polygon to remove from subarea">
                    <span>
                      <DrawIconButton
                        disableRipple
                        selected={isPolygonSubtractMode}
                        size="small"
                        disabled={!ODDatasetConfiguration.data?.subAreaGeometry}
                        onClick={handleSubtractMode}
                      >
                        <Icon icon="cut" />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      !isPolygonSelected
                        ? "Select subarea by clicking inside it in order to delete it"
                        : "Delete selected polygon or vertex"
                    }
                  >
                    <span>
                      <DrawIconButton
                        disableRipple
                        color="default"
                        size="small"
                        disabled={!isPolygonSelected || !ODDatasetConfiguration.data?.subAreaGeometry}
                        onClick={() => draw.trash()}
                      >
                        <Icon icon="trash" />
                      </DrawIconButton>
                    </span>
                  </Tooltip>
                </DrawControls>
              </DrawControlsWrapper>

              <RoadClassSelector
                roadClasses={roadClasses}
                savedRoadClasses={ODDatasetConfiguration.data?.gateRoadClasses || []}
                setRoadClasses={setRoadClasses}
              />

              <SpaceBetweenContainer>
                <Button type="button" color="primaryLight" size="sm" onClick={toggleGateEditorPanel}>
                  Define gates
                </Button>
                {(ODDatasetConfiguration.data?.gates?.length as number) > 0 && (
                  <Badge color="secondary">{`${ODDatasetConfiguration.data?.gates?.length} gates`}</Badge>
                )}
              </SpaceBetweenContainer>

              <Divider />

              <SpaceBetweenContainer>
                <Label>Zoning</Label>
                <Badge color="secondary">
                  {subareaState.state === DataState.LOADING
                    ? "loading..."
                    : `${subareaState.data?.zoneIds.length || 0} zones`}
                </Badge>
              </SpaceBetweenContainer>
              <ZoningSelector
                activeItem={selectedZoning}
                customZoningId={customZoningId}
                onItemSelect={setSelectedZoning}
                zoningOptions={zoningOptions}
              />
            </FieldsContainer>

            <div>
              <ActionButtons>
                {touched && (
                  <>
                    <Button
                      color="white"
                      size="sm"
                      type="button"
                      onClick={() => handleRevertChanges(handleReset)}
                      disabled={loading}
                    >
                      Revert
                    </Button>
                    <Button color="white" size="sm" type="submit" disabled={loading}>
                      Save
                    </Button>
                  </>
                )}
                <ComputeButton
                  size="sm"
                  type="button"
                  disabled={loading || !ODDatasetConfiguration.data?.permissions.compute.allow}
                  onClick={handleComputeSubmit}
                >
                  Compute...
                </ComputeButton>
              </ActionButtons>
              <Divider />
              <SpaceBetweenContainer>
                <Label>Map Layers</Label>
                <Popover
                  placement="right-end"
                  content={
                    <MapLayersWrapper>
                      <Switch label="Gates" style={{ margin: 0 }} checked={showGates} onChange={changeShowGates} />
                      <Switch label="Roads" style={{ margin: 0 }} checked={showRoads} onChange={changeShowRoads} />
                      <Switch checked={showZones} label="Zones" style={{ margin: 0 }} onChange={changeShowZones} />
                    </MapLayersWrapper>
                  }
                  renderTarget={({ isOpen, ref, ...targetProps }) => (
                    <IconButton
                      icon="cog"
                      variant="squared"
                      color="white"
                      size="sm"
                      type="button"
                      ref={ref}
                      {...targetProps}
                    />
                  )}
                />
              </SpaceBetweenContainer>
            </div>
            {isUnsavedChangesBeforeCompute && (
              <ConfirmDialog
                title="Confirm compute"
                description="You have unsaved configuration changes. Do you want to save them and continue?"
                confirmButtonText="Save and continue"
                onClose={handleCloseConfirmSaveBeforeCompute}
                onSubmit={() =>
                  handleConfirmSaveBeforeComputeSubmit(values, {
                    setSubmitting,
                  } as unknown as FormikHelpers<DatasetForm>)
                }
              />
            )}
          </FormWrapper>
        )}
      </>
    );
  },
);
