import { FC } from "react";

import { BluePoint, Description, HeadCellAlignCenter, Table, TableHeadRow } from "./Table";

export const AoiTripsTable: FC = () => (
  <>
    <Description>
      The following table shows the trips displayed on the OD Matrix map compared to the export content for{" "}
      <strong>area of interest</strong>:{" "}
    </Description>
    <Table compact bordered>
      <thead>
        <TableHeadRow>
          <th>Trip in Area of Interest</th>
          <HeadCellAlignCenter>Included on Map</HeadCellAlignCenter>
          <HeadCellAlignCenter>Included in Export</HeadCellAlignCenter>
        </TableHeadRow>
      </thead>
      <tbody>
        <tr>
          <td>Internal - Internal</td>
          <td>
            <BluePoint />
          </td>
          <td>
            <BluePoint />
          </td>
        </tr>
        <tr>
          <td>Internal - External</td>
          <td>
            <BluePoint />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>External - Internal</td>
          <td>
            <BluePoint />
          </td>
          <td></td>
        </tr>
        <tr>
          <td>External - External</td>
          <td>
            <BluePoint />
          </td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  </>
);
