import { CircularProgress, Grid, Stack } from "@mui/material";
import { FC, useMemo } from "react";

import { useAppSelector } from "hooks";

import { selectScreenlinesLoading } from "store/sections/screenlines";
import { selectSelectedScreenline } from "store/sections/screenlines";

import { ScreenlineIntersection } from "./ScreenlineIntersection";
import { getIntersectionGroups } from "./utils";

export interface IntersectionPopupContentProps {
  featuresProps: any[];
}

export const IntersectionPopupContent: FC<IntersectionPopupContentProps> = ({ featuresProps }) => {
  const loading = useAppSelector(selectScreenlinesLoading);
  const selectedScreenline = useAppSelector(selectSelectedScreenline);
  const candidateSegments = useAppSelector(
    (state) => state.screenlines.screenlineValidation.data?.candidateSegments || [],
  );
  const resolvedSegments = useAppSelector(
    (state) => state.screenlines.screenlineValidation.data?.resolvedSegments || [],
  );

  const intersectionGroups = useMemo(
    () =>
      getIntersectionGroups(
        candidateSegments,
        resolvedSegments,
        featuresProps.map((f) => f.fraction),
      ),
    [candidateSegments, resolvedSegments, featuresProps],
  );

  return (
    <Grid padding={"4px 8px"}>
      {loading ? (
        <Grid container alignItems={"center"} justifyContent={"center"} minWidth={200} minHeight={32}>
          <CircularProgress size={16} color="secondary" />
        </Grid>
      ) : (
        <Stack spacing={1}>
          {intersectionGroups.map(([intersectionGroupId, intersectionGroup], i) => (
            <ScreenlineIntersection
              key={i}
              selected
              intersectionGroup={intersectionGroup}
              leftLabel={selectedScreenline?.leftLabel || ""}
              rightLabel={selectedScreenline?.rightLabel || ""}
              intersectionDirectionFilter={selectedScreenline?.intersectionDirectionFilter}
            />
          ))}
        </Stack>
      )}
    </Grid>
  );
};
