import { Icon } from "@blueprintjs/core";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React, { useCallback, useRef, useState } from "react";

import { Input, Menu } from "components";

import { useDebounce } from "hooks";

import { GeocodingSearchResults } from "types";

const MIN_SEARCH_TEXT_LENGTH = 5;

interface Props {
  geocodingSearchResults: GeocodingSearchResults | null;
  geocodingSearchByText: (text: string) => void;
  clearGeocodingSearch: () => void;
  flyTo: (coordinates: [longitude: number, latitude: number], placeName: string) => void;
  removeSearchMarker: () => void;
}

const InputWrapper = styled(Box)`
  position: absolute;
  top: 18px;
  left: 348px;
  width: 100%;
  z-index: 2;

  & .bp4-input {
    width: 100%;
    height: 29px !important;
    border: none !important;
    padding-right: 5px;
  }

  & .bp4-input-group {
    display: flex;
    max-width: 220px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: #fff;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);

    border-radius: 4px;
  }

  & .bp4-icon {
    margin: 3px;
  }

  & .bp4-input-action {
    width: 24px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .bp4-icon.bp4-icon-small-cross {
    margin: 0 !important;
    padding: 0 !important;
  }

  & .bp4-input-action {
    margin: 0;
    padding: 0;
  }

  & .bp4-menu {
    width: 100%;
    max-width: 400px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    margin-top: 1px;
  }
`;

const CrossIcon = styled(Icon)`
  &:hover {
    cursor: pointer;

    & svg {
      fill: var(--color-gray-800) !important;
    }
  }
`;

const GeocodingSearchBox = ({
  geocodingSearchResults,
  geocodingSearchByText,
  clearGeocodingSearch,
  flyTo,
  removeSearchMarker,
}: Props) => {
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef<any>(null);

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);

    debouncedSearchRequest();
  };

  const resetSearchText = () => {
    if (searchText !== "") {
      setSearchText("");
      clearGeocodingSearch();
      removeSearchMarker();
    }
  };

  // const getResultTextLabel = (result: string) => {
  //   const resultText = result.split(", ");
  //   const restTextPart = resultText.slice(1, resultText.length).join(", ");

  //   return `${resultText[0]} - ${restTextPart}`;
  // };

  const debouncedSearchRequest = useDebounce(() => {
    if (searchText.length >= MIN_SEARCH_TEXT_LENGTH) {
      geocodingSearchByText(searchText);
    } else {
      clearGeocodingSearch();
    }
  }, 250);

  const handleOnBlur = useCallback(() => {
    clearGeocodingSearch();
  }, [clearGeocodingSearch]);

  const getMenuItems = useCallback(() => {
    if (geocodingSearchResults) {
      return geocodingSearchResults.features.map((result) => ({
        key: result.id,
        text: result.place_name,
        onMouseDown: (event: any) => {
          event.preventDefault();
        },
        onClick: () => {
          if (searchInput.current) {
            searchInput.current.blur();
          }
          setSearchText(result.place_name);
          handleOnBlur();
          flyTo(result.center, result.place_name);
        },
      }));
    }

    return [];
  }, [geocodingSearchResults, handleOnBlur, flyTo]);

  const handleOnClick = () => {
    if (searchText.length >= MIN_SEARCH_TEXT_LENGTH && !geocodingSearchResults) {
      geocodingSearchByText(searchText);
    }
  };

  const menuItems = getMenuItems();

  return (
    <InputWrapper>
      <Input
        inputRef={searchInput}
        leftIcon="search"
        placeholder="Search..."
        rightElement={searchText ? <CrossIcon icon="small-cross" onClick={resetSearchText} /> : undefined}
        onClick={handleOnClick}
        onBlur={handleOnBlur}
        onChange={handleChangeSearchText}
        value={searchText}
      />
      {menuItems.length > 0 && <Menu menuItems={menuItems} />}
    </InputWrapper>
  );
};

export const GeocodingSearch = React.memo(GeocodingSearchBox);
