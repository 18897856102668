import { useAuth0 } from "@auth0/auth0-react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Popover2 as Popover } from "@blueprintjs/popover2";
import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { HelpForm } from "components";
import { Avatar, FlexContainer, Menu, MenuItem, MenuItemProps, Notifications } from "components";

import { useAppDispatch, useAppSelector, usePrevious } from "hooks";

import { licenseActions } from "store/sections/license";

import { Organization } from "types";

const UserButton = styled(FlexContainer)`
  min-width: 62px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`;

const UserControlsContainer = styled(FlexContainer)`
  gap: 30px;
`;

const SubMenuContainer = styled.div`
  max-height: 350px;
  overflow-y: auto;
  padding-right: 0.5rem;
`;

export const UserControls: FC = () => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth0();

  const isOperator = useAppSelector((state) => state.license.user.data?.isOperator);
  const organizations = useAppSelector((state) => state.license.organization.data);
  const licenseTypes = useAppSelector((state) => state.license.licenseTypes.data);
  const userOrganization = useAppSelector((state) => state.license.user.data?.organization);
  const userLicenseType = useAppSelector((state) => state.license.user.data?.softwareLicense);
  const previousUserOrganization = usePrevious(userOrganization);
  const previousUserLicenseType = usePrevious(userLicenseType);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const isUserOrganisationChanged = useMemo(
    () => userOrganization && previousUserOrganization && previousUserOrganization !== userOrganization,
    [userOrganization, previousUserOrganization],
  );

  const isUserLicenseTypeChanged = useMemo(
    () => userLicenseType && previousUserLicenseType && previousUserLicenseType !== userLicenseType,
    [userLicenseType, previousUserLicenseType],
  );

  const sortedOrganizations = useMemo(
    () => [...(organizations || [])].sort((a, b) => a.name.localeCompare(b.name)),
    [organizations],
  );

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchOrganization());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isOperator) dispatch(licenseActions.fetchLicenseTypes());
  }, [isOperator, dispatch]);

  useEffect(() => {
    if (isUserOrganisationChanged || isUserLicenseTypeChanged) {
      window.location.replace("/");
    }
  }, [isUserOrganisationChanged, isUserLicenseTypeChanged]);

  const changeOrganization = useCallback(
    (code: string) => {
      dispatch(licenseActions.changeUserOrganization(code));
    },
    [dispatch],
  );

  const changeLicenseType = useCallback(
    (code: string) => {
      dispatch(licenseActions.changeUserLicenseType(code));
    },
    [dispatch],
  );

  const handleSelectOrganization = useCallback(
    (event: SyntheticEvent<Element, Event>, org: Organization | null) => {
      if (org) changeOrganization(org.code);
    },
    [changeOrganization],
  );

  const userMenuItems: MenuItemProps[] = useMemo(
    () => [
      ...(isOperator
        ? [
            {
              text: "Copy token",
              onClick: () => {
                navigator.clipboard.writeText(sessionStorage.getItem("accessToken") || "");
              },
            },
            {
              text: "Switch organization",
              children: (
                <SubMenuContainer>
                  <Autocomplete
                    size="small"
                    sx={{ minWidth: 300 }}
                    popupIcon={null}
                    ListboxProps={{
                      style: {
                        fontSize: "14px",
                      },
                    }}
                    options={sortedOrganizations}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(opt) => opt.name}
                    isOptionEqualToValue={(opt: Organization, value: Organization) => opt.id === value.id}
                    value={userOrganization}
                    onChange={handleSelectOrganization}
                    autoHighlight
                    disablePortal
                    open
                  />
                </SubMenuContainer>
              ),
            },
            {
              text: "Switch license type",
              children: (
                <SubMenuContainer>
                  {licenseTypes?.map((license) => (
                    <MenuItem
                      key={license.id}
                      selected={userLicenseType?.id === license.id}
                      text={license.name}
                      onClick={() => changeLicenseType(license.code)}
                    />
                  ))}
                </SubMenuContainer>
              ),
            },
          ]
        : []),
      {
        text: "Sign out",
        onClick: () =>
          logout({
            returnTo: window.location.origin,
          }),
      },
    ],
    [
      isOperator,
      sortedOrganizations,
      licenseTypes,
      userLicenseType?.id,
      userOrganization,
      changeLicenseType,
      handleSelectOrganization,
      logout,
    ],
  );

  return (
    <UserControlsContainer>
      <Notifications />
      <Icon icon={IconNames.COMMENT} color="white" size={18} onClick={() => setIsHelpFormOpen(true)} />
      <HelpForm isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} />
      <Popover
        minimal
        placement="bottom-start"
        content={<Menu menuItems={userMenuItems} />}
        renderTarget={({ isOpen, ref, ...targetProps }) => (
          <UserButton {...targetProps} ref={ref}>
            <Avatar src={user?.picture} alt="avatar" width={40} height={40} />
            <Icon icon={isOpen ? "chevron-up" : "chevron-down"} color="white" size={18} />
          </UserButton>
        )}
      />
    </UserControlsContainer>
  );
};
