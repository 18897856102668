import { IconName } from "@blueprintjs/core";

import { getSelectedVolumeObject } from "features/map/utils";

import { VolumeCount } from "components";

import { LEFT_SIDE_DIRECTIONS, SelectedSegmentConfig, SelectedVolume, Volume } from "types";

export const getOpenChevronIcon = (isOpen: boolean, placement: "bottom" | "top") => {
  if (placement === "bottom") {
    return isOpen ? "chevron-up" : "chevron-down";
  } else {
    return isOpen ? "chevron-down" : "chevron-up";
  }
};

export const getDirectionArrowIcon = (direction: string): IconName => {
  switch (direction) {
    case "N":
      return "arrow-up";
    case "S":
      return "arrow-down";
    case "E":
      return "arrow-right";
    case "W":
      return "arrow-left";
    case "NE":
      return "arrow-top-right";
    case "NW":
      return "arrow-top-left";
    case "SE":
      return "arrow-bottom-right";
    case "SW":
      return "arrow-bottom-left";
    default:
      return "arrow-up";
  }
};

export const getVolumesByDirections = (
  volume: Volume,
  isPedestriansMode: boolean,
  couldBeAdded?: boolean,
  onClick?: (selectedVolume: SelectedVolume, id: string) => void,
) => {
  const handleClick = (segmentId: string) => {
    if (onClick) {
      onClick(getSelectedVolumeObject(volume), segmentId);
    }
  };

  if (isPedestriansMode) {
    return (
      <>
        <VolumeCount
          icon={null}
          count={volume.seg_pair ? volume.volumeTF + volume.volumeFT : volume.volumeFT}
          couldBeAdded={couldBeAdded}
          onClick={() => handleClick(volume.fromToId)}
        />
      </>
    );
  }

  if (volume.seg_pair && LEFT_SIDE_DIRECTIONS.indexOf(volume.tf_dir) !== -1) {
    return (
      <>
        <VolumeCount
          icon={getDirectionArrowIcon(volume.tf_dir)}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
        />
        <VolumeCount
          icon={getDirectionArrowIcon(volume.ft_dir)}
          count={volume.volumeFT}
          onClick={() => handleClick(volume.fromToId)}
          couldBeAdded={couldBeAdded}
        />
      </>
    );
  }

  return (
    <>
      {!volume.seg_pair && <td onClick={() => handleClick(volume.fromToId)}></td>}
      <VolumeCount
        icon={getDirectionArrowIcon(volume.ft_dir)}
        count={volume.volumeFT}
        onClick={() => handleClick(volume.fromToId)}
        couldBeAdded={couldBeAdded}
      />
      {volume.seg_pair && (
        <VolumeCount
          icon={getDirectionArrowIcon(volume.tf_dir)}
          count={volume.volumeTF}
          onClick={() => handleClick(volume.toFromId)}
          couldBeAdded={couldBeAdded}
        />
      )}
    </>
  );
};

export const getSelectedSegmentConfig = (selectedVolume: SelectedVolume, id: string): SelectedSegmentConfig => {
  return {
    segmentId: id,
    streetName: selectedVolume.streetName,
    directionIcon:
      id === selectedVolume.tfSegmentId && selectedVolume.tfDirectionIcon
        ? selectedVolume.tfDirectionIcon
        : selectedVolume.ftDirectionIcon,
    lon: selectedVolume.lngLat[0],
    lat: selectedVolume.lngLat[1],
    fromToId: selectedVolume.feature.fromToId,
  };
};
