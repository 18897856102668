import styled from "@emotion/styled";
import { FC, Fragment, MutableRefObject, RefObject, createRef, useEffect, useRef } from "react";

import { AoiTripsTable } from "./AoiTripsTable";
import { DatasetTripsTable } from "./DatasetTripsTable";
import { FileTable } from "./FileTable";
import { Description, Footnote, SubTitle, Table, TableHeadRow } from "./Table";
import {
  ODShapeFileColumns,
  ODVolumesFileColumns,
  gatesFileColums,
  gatesShapeFileColumns,
  roadShapeFileColumns,
  roadVolumesFileColumns,
  subareaShapeFileColumns,
} from "./data";
import { roadAadtVmtFileColumns } from "./data/roadAadtVmtFileColumns";
import { roadMileageFileColumns } from "./data/roadMileageFileColumns";

type ContentRefs = Map<string, RefObject<HTMLElement>>;

interface ContentData {
  label: string;
  title: string;
  data: any[];
  description: string;
  note: string;
}

const TableLink = styled.p`
  text-decoration: underline;
  color: blue;
  cursor: pointer;
  margin: 0;
`;

const exportContentData: ContentData[] = [
  {
    label: "gates.csv",
    title: "Gates (only present in dataset export, “gates.csv”)",
    data: gatesFileColums,
    description: "List of gate segments.",
    note: "",
  },
  {
    label: "od_data.csv",
    title: "OD volumes (“od_data.csv”)",
    data: ODVolumesFileColumns,
    description: "OD flows within the subarea.",
    note: "",
  },
  {
    label: "zones.shp (+.dbf/.prj/.shx)",
    title: "OD zone shape files (“zones.shp”)",
    data: ODShapeFileColumns,
    description: "Zones inside the dataset sub-area, with incoming/outgoing counts and breakdowns.",
    note: "",
  },
  {
    label: "volumes.csv",
    title: "Road volumes (“volumes.csv”)",
    data: roadVolumesFileColumns,
    description:
      "Volumes for selected road classes within the dataset sub area (does not include gate segments volumes).",
    note: "",
  },
  {
    label: "road_segments.shp (+.dbf/.prj/.shx)",
    title: "Road network shape file (“road_segments.shp”)",
    data: roadShapeFileColumns,
    description:
      "Segment features (linestrings with attributes) intersecting the subarea, including all gate segments (even if they are outside the subarea). Also including generated gate connectors from gate segments to the gate location.",
    note: "",
  },
  {
    label: "subarea.shp (+.dbf/.prj/.shx)",
    title: "Subarea shape file (“subarea.shp”)",
    data: subareaShapeFileColumns,
    description: "Subarea polygon with attributes describing the dataset.",
    note: "",
  },
  {
    label: "gates.shp (+.dbf/.prj/.shx)",
    title: "Gates shape files (“gates.shp”)",
    data: gatesShapeFileColumns,
    description:
      "Contains gates, their AADT and breakdowns in the same structure as zones shapefile. Only provided for datasets with gates.",
    note: "",
  },
];

const vmtExportContentData: ContentData[] = [
  {
    label: "road_aadt_vmt_zone_counts.csv",
    title: "AADT VMT zone counts (road_aadt_vmt_zone_counts.csv)",
    data: roadAadtVmtFileColumns,
    description: "Average annual daily trips VMT counts per zone id",
    note: "",
  },
  {
    label: "road_mileage_zone_counts.csv",
    title: "VMT mileage (“road_mileage_zone_counts.csv”)",
    data: roadMileageFileColumns,
    description: "VMT mileage",
    note: "",
  },
];

function listFileColumns(contentData: ContentData[], refs: MutableRefObject<ContentRefs>) {
  return contentData.map((file, i) => (
    <Fragment key={`${file.label}-${i}`}>
      <SubTitle ref={refs.current.get(file.label) as RefObject<HTMLHeadingElement>}>{file.title}</SubTitle>
      <FileTable fileColumns={file.data} />
      {file.note && <Description style={{ margin: "-2rem 0 3rem 0", fontWeight: 600 }}>{file.note}</Description>}
    </Fragment>
  ));
}

function listFileNames(
  contentData: ContentData[],
  scrollToRef: (ref: RefObject<HTMLElement>) => void,
  refs: MutableRefObject<ContentRefs>,
) {
  return contentData.map((file, i) => (
    <tr key={i}>
      <td width={"280px"}>
        <TableLink onClick={() => scrollToRef(refs.current.get(file.label)!)}>{file.label}</TableLink>
      </td>
      <td>{file.description}</td>
    </tr>
  ));
}

export const ExportContent: FC = () => {
  const refs = useRef<ContentRefs>(new Map());

  const scrollToRef = (ref: RefObject<HTMLElement>) => {
    if (!ref.current) return;

    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (!refs.current.size) {
      for (const { label } of [...exportContentData, ...vmtExportContentData]) {
        refs.current.set(label, createRef());
      }
    }
  }, []);

  return (
    <>
      <Description>
        A core capability of OpenPaths Patterns is the export function, allowing users to work with OD and road datasets
        in other GIS and transport modeling tools. The following data sets and fields are being provided as part of an
        export.
      </Description>

      <SubTitle style={{ marginTop: "2rem" }}>
        Correspondence between data shown in dashboard and data exported
      </SubTitle>
      <Description style={{ marginBottom: "2rem" }}>
        The driving design principle behind our map display is to show all mobility in the entire area of interest or
        dataset. This includes trips that start or end outside of the area of interest or dataset. In contrast to that,
        the export content is restricted to trips that start and end inside of the area of interest and external gates
        (in the case of datasets).
      </Description>

      <AoiTripsTable />

      <DatasetTripsTable />

      <SubTitle>Files contained in the export </SubTitle>
      <Table compact bordered>
        <thead>
          <TableHeadRow>
            <th>Filename</th>
            <th>Content</th>
          </TableHeadRow>
        </thead>
        <tbody>{listFileNames(exportContentData, scrollToRef, refs)}</tbody>
      </Table>
      {listFileColumns(exportContentData, refs)}
      <SubTitle>Files contained in the road VMT export </SubTitle>
      <Table compact bordered>
        <thead>
          <TableHeadRow>
            <th>Filename</th>
            <th>Content</th>
          </TableHeadRow>
        </thead>
        <tbody>{listFileNames(vmtExportContentData, scrollToRef, refs)}</tbody>
      </Table>
      {listFileColumns(vmtExportContentData, refs)}
      <Footnote> 1. Based on US Census 2020 Urban Areas</Footnote>
      <Footnote>
        {" "}
        2. Source: https://geo.dot.gov/server/rest/services/Hosted. OpenPaths Patterns has tried to accurately transfer
        Highway Performance Monitoring System (HPMS) data to the OSM network. Although our data will not exactly match
        State and Federal reports, we have validated the reasonableness of the transfer process. Some differences are
        attributable to variations in geometric representation of highway infrastructure and estimated traffic volumes.
      </Footnote>
      <Footnote>
        {" "}
        3. State DOT road mileage reports provide centerline miles, while OpenPaths Patterns provides road miles.
        Centerline miles summarizes the one direction length of a highway, regardless of how simple or complex the
        cross-section is represented in mapping geometry. Road miles summarizes the length of road segments as
        represented in the mapping geometry including directionality.
      </Footnote>
    </>
  );
};
