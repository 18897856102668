import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { Screenline } from "api/analytics/index.d";
import { FC } from "react";

import { MuiDialog } from "components";

export interface DeleteScreenlineDialogProps {
  screenlineToDelete: Screenline | null;
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteScreenline: () => void;
}

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "400px",
    minHeight: "auto",
  },
}));

export const DeleteScreenlineDialog: FC<DeleteScreenlineDialogProps> = ({
  screenlineToDelete,
  isOpen,
  onClose,
  handleConfirmDeleteScreenline,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} title={"Delete screenline " + screenlineToDelete?.id}>
      <Box padding={2} marginTop={"60px"}>
        <Typography variant="body2" marginBottom={2}>
          Are you sure you want to delete this screenline?
        </Typography>
        <Grid container justifyContent={"flex-end"} gap={1}>
          <Button size="small" color="secondary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button size="small" color="secondary" onClick={handleConfirmDeleteScreenline}>
            Delete
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
};
