import { useAuth0 } from "@auth0/auth0-react";
import { LayerManager } from "@daturon/mapboxgl-layer-manager";
import { CircularProgress, styled } from "@mui/material";
import { LngLatLike, Marker } from "mapbox-gl";
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ModuleData } from "features/map/ModuleManager";
import { EmptyResultWarning } from "features/select-link/EmptyResultWarning";
import { SegmentMarker } from "features/select-link/types";
import { selectColorOrGenerate } from "features/select-link/utils";

import { RightSidebar, ToggleButtons } from "components";

import {
  addSegmentToGroups,
  deleteSegmentFromGroup,
  segmentExistsInGroups,
  updateFeatureState,
} from "components/pages/analytics/select-link/utils";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { selectLinkActions } from "store/sections/selectLink";

import {
  FiltersType,
  FocusAreaItem,
  SegmentsGroup,
  SelectLinkAnalysisOptions,
  SelectLinkMode,
  SelectLinkPredicateLogic,
  SelectedArea,
  SelectedSegmentConfig,
  SelectedVolume,
  ZoneSelectionMode,
} from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";
import { getSelectedSegmentConfig } from "utils/ui";

import { ROADS_SOURCE_ID } from "../roads/map-data/sources";
import { MapController } from "./MapController";
import { SelectLinkConfigSettings } from "./SelectLinkConfigSettings";
import { SelectLinkLinksConfigPanel } from "./SelectLinkLinksConfigPanel";
import { SelectLinkZonesConfigPanel } from "./SelectLinkZonesConfigPanel";

interface SelectLinkModuleComponentProps {
  map: MutableRefObject<mapboxgl.Map | null>;
  layerManagerRef: MutableRefObject<LayerManager | null>;
  selectLinkMode: SelectLinkMode;
  selectLinkConfigLinksModuleData: ModuleData | null;
  isExportPermitted: boolean;
  selectLinkConfigZonesModuleData: ModuleData | null;
  setSelectLinkConfigLinksModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkConfigZonesModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  setSelectLinkResultsModuleData: Dispatch<SetStateAction<ModuleData | null>>;
  updateODModeCounts: MutableRefObject<(() => void) | null>;
  updateRoadsModeCounts: MutableRefObject<(() => void) | null>;
  deleteSelectedZone: (zone: SelectedArea) => void;
  setSelectLinkMode: Dispatch<SetStateAction<SelectLinkMode>>;
}

const SegmentsContainer = styled("div")`
  display: flex;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  height: calc(100% - 150px);
  width: 100%;
`;

export const SelectLinkModuleComponent = ({
  map,
  layerManagerRef,
  selectLinkMode,
  selectLinkConfigLinksModuleData,
  isExportPermitted,
  selectLinkConfigZonesModuleData,
  setSelectLinkConfigLinksModuleData,
  setSelectLinkConfigZonesModuleData,
  setSelectLinkResultsModuleData,
  updateODModeCounts,
  updateRoadsModeCounts,
  deleteSelectedZone,
  setSelectLinkMode,
}: SelectLinkModuleComponentProps) => {
  const { user } = useAuth0();
  const dispatch = useAppDispatch();

  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);
  const currentRoadFilters = useAppSelector((state) => state.filters.roadFilters);
  const selectedFocusArea = useAppSelector((state) => state.global.selectedFocusArea);

  const selectLinkSegmentCounts = useAppSelector((state) => state.selectLink.selectLinkSegmentCounts);
  const selectLinkConfig = useAppSelector((state) => state.selectLink.selectLinkConfig);

  // Config
  const [selectLinkOptions, setSelectLinkOptions] = useState<SelectLinkAnalysisOptions>({
    minVolume: selectLinkConfigLinksModuleData?.data.minAllowedVolume,
  });

  // Zones
  const [selectedOrigins, setSelectedOrigins] = useState<SelectedArea[]>([]);
  const [selectedDestinations, setSelectedDestinations] = useState<SelectedArea[]>([]);
  const [selectedZone, setSelectedZone] = useState<SelectedArea | null>(null);

  // Links
  const [activeGroupId, setActiveGroupId] = useState<string>("");
  const [selectedLinkGroups, setSelectedLinkGroups] = useState<SegmentsGroup[]>([]);
  const [maxSegmentsGroupId, setMaxSegmentsGroupId] = useState<number>(0);
  const [selectLinkPredicateLogic, setSelectLinkPredicateLogic] = useState<SelectLinkPredicateLogic>(
    SelectLinkPredicateLogic.And,
  );
  const [selectLinkMarkers, setSelectLinkMarkers] = useState<SegmentMarker[]>([]);

  const [isEmptyResultWarningOpen, setIsEmptyResultWarningOpen] = useState(false);

  const areSelectLinkResultsAvailable = useMemo(() => {
    return selectLinkSegmentCounts.state === DataState.AVAILABLE;
  }, [selectLinkSegmentCounts.state]);

  const areConfigChangesPending = useMemo(() => {
    if (selectLinkConfig.data) {
      const currentConfig = {
        segmentGroups: selectedLinkGroups,
        origins: selectedOrigins,
        destinations: selectedDestinations,
        predicateLogic: selectLinkPredicateLogic,
        minVolume: selectLinkOptions.minVolume,
      };
      const lastSavedConfig = {
        segmentGroups: selectLinkConfig.data.segmentsGroups,
        origins: selectLinkConfig.data.origins,
        destinations: selectLinkConfig.data.destinations,
        predicateLogic: selectLinkConfig.data.segmentsGroupsOp,
        minVolume: selectLinkConfig.data.minCount,
      };
      const areConfigsEqual = JSON.stringify(lastSavedConfig) === JSON.stringify(currentConfig);
      return !areConfigsEqual;
    }
    return false;
  }, [
    selectLinkConfig.data,
    selectedLinkGroups,
    selectedOrigins,
    selectedDestinations,
    selectLinkPredicateLogic,
    selectLinkOptions.minVolume,
  ]);

  const areSelectLinkResultsEmpty = useMemo(() => {
    return Boolean(selectLinkSegmentCounts?.data) && (selectLinkSegmentCounts?.data?.segmentVolumes?.size || 0) === 0;
  }, [selectLinkSegmentCounts.data]);

  const zoneSelectionModeRef = useRef<ZoneSelectionMode>(ZoneSelectionMode.Origins);

  const selectedOriginsRef = useRef<SelectedArea[]>([]);
  const selectedDestinationsRef = useRef<SelectedArea[]>([]);

  const updateFeatureStateForRoads = useRef<
    ((segmentId: string | null, stateName: string, status?: boolean | undefined) => void) | null
  >(null);

  const updateSelectLinkConfiguration = useRef<
    (
      selectedLinkGroups: SegmentsGroup[],
      selectedOrigins: SelectedArea[],
      selectedDestinations: SelectedArea[],
      selectLinkPredicateLogic: SelectLinkPredicateLogic,
    ) => void
  >(() => {});

  const getSelectLinkSegmentCounts = useRef<
    (
      filters: FiltersType,
      selectedFocusArea: FocusAreaItem,
      segmentGroups: SegmentsGroup[],
      origins: SelectedArea[],
      destinations: SelectedArea[],
      predicateLogic: string,
      { minVolume }: SelectLinkAnalysisOptions,
    ) => void
  >(() => {});

  const createNewSegmentMarker = useCallback(
    (segmentId: string, lon: number, lat: number, color?: string) => {
      const marker = new Marker({ scale: 0.6, offset: [0, 0], color: color });

      marker.setLngLat([lon, lat]).addTo(map.current!);

      return {
        segmentId,
        marker,
      };
    },
    [map],
  );

  const addAllMarkersToMap = useCallback(
    (segmentGroups: SegmentsGroup[]) => {
      const markers = segmentGroups.flatMap((group) => {
        return group.segments.reduce((acc, segment) => {
          const { segmentId, lon, lat } = segment;
          const markerExists = acc.some(({ segmentId: id }) => id === segmentId);

          if (!markerExists) {
            const marker = createNewSegmentMarker(segmentId, lon, lat, group?.color);

            return [...acc, marker];
          }

          return acc;
        }, [] as SegmentMarker[]);
      });
      setSelectLinkMarkers(markers);
    },
    [createNewSegmentMarker],
  );

  const createMarkerAndAddToMap = useCallback(
    (segmentConfig: SelectedSegmentConfig, segmentGroups: SegmentsGroup[]) => {
      const activeSegmentsGroup = activeGroupId;

      if (!activeSegmentsGroup) return;

      const group = segmentGroups.find((group) => group.groupName === activeSegmentsGroup);
      const { segmentId, lon, lat } = segmentConfig;
      const segmentMarker = createNewSegmentMarker(segmentId, lon, lat, group?.color);

      selectLinkMarkers.push(segmentMarker);
      setSelectLinkMarkers(selectLinkMarkers);
    },
    [activeGroupId, selectLinkMarkers, createNewSegmentMarker],
  );

  /**
   * Finds the marker to delete by its coordinates and removes it from the state and from the map, if it exists.
   */
  const deleteMarkerForSegmentAndRemoveFromMap = useCallback(
    (
      markers: SegmentMarker[],
      segment: SelectedSegmentConfig,
      setMarkers: (markers: SegmentMarker[]) => void,
    ): void => {
      const newMarkers = markers.filter(({ segmentId, marker }) => {
        if (segmentId === segment.segmentId) marker.remove();

        return segmentId !== segment.segmentId;
      });
      setMarkers(newMarkers);
    },
    [],
  );

  const handleAddNewSegmentsGroup = useCallback(
    (group: SegmentsGroup, id: number) => {
      const newGroups = [...selectedLinkGroups, group];
      setSelectedLinkGroups(newGroups);
      setActiveGroupId(group.groupName);
      setMaxSegmentsGroupId(id);
    },
    [selectedLinkGroups, setSelectedLinkGroups, setMaxSegmentsGroupId],
  );

  useEffect(() => {
    if (selectLinkConfigLinksModuleData) {
      if (!selectLinkMarkers.length) {
        addAllMarkersToMap(selectedLinkGroups);
      }
    }
  }, [selectLinkConfigLinksModuleData, selectLinkMarkers.length, selectedLinkGroups, addAllMarkersToMap]);

  useEffect(() => {
    if (areSelectLinkResultsEmpty) {
      setIsEmptyResultWarningOpen(true);
    }
  }, [areSelectLinkResultsEmpty]);

  // Create a new select link group if none exists
  useEffect(() => {
    if (selectLinkConfig.data && !selectLinkConfig.data?.segmentsGroups?.length && !selectedLinkGroups.length) {
      const id = maxSegmentsGroupId + 1;
      const newGroup = {
        groupName: `${id}`,
        segments: [],
        color: selectColorOrGenerate(new Set(selectedLinkGroups.map(({ color }) => color))),
      };

      handleAddNewSegmentsGroup(newGroup, id);
    }
  }, [selectLinkConfig.data, selectedLinkGroups, maxSegmentsGroupId, handleAddNewSegmentsGroup]);

  // Update select link export button state based on the availability of results and pending config changes
  useEffect(() => {
    dispatch(
      selectLinkActions.setSelectLinkExportDisabled(
        !isExportPermitted || !areSelectLinkResultsAvailable || areConfigChangesPending || areSelectLinkResultsEmpty,
      ),
    );
  }, [areSelectLinkResultsAvailable, areConfigChangesPending, areSelectLinkResultsEmpty, isExportPermitted, dispatch]);

  /**
   * This callback zooms onto the selected link segment when it is being clicked on the right side config pane.
   */
  const handleZoomOnSegment = useCallback(
    (lngLat: LngLatLike, zoom: number = 14) => {
      if (map.current) {
        map.current.flyTo({
          center: lngLat,
          zoom,
        });
      }
    },
    [map],
  );

  const updateMapOnDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig) => {
      // switch off permanent highlight feature and delete the marker for both roads and select link layers
      if (updateFeatureStateForRoads.current) {
        const segmentFromToId = segment.fromToId;
        updateFeatureStateForRoads.current(segmentFromToId, "selectHighlight", false);
        updateFeatureStateForRoads.current(segmentFromToId, "permanentSelectHighlight", false);
      }
      deleteMarkerForSegmentAndRemoveFromMap(selectLinkMarkers, segment, setSelectLinkMarkers);
    },
    [updateFeatureStateForRoads, selectLinkMarkers, deleteMarkerForSegmentAndRemoveFromMap],
  );

  /**
   * This callback handles deletion of selected link segment in the right config pane:
   * - deletes the segments from the state
   * - deletes the permanently highlighted feature from the feature state (if both directional segments were removed from the list)
   * - deletes the marker from the map adn the state (if both directional segments were removed from the list)
   */
  const handleDeleteSelectedLink = useCallback(
    (segment: SelectedSegmentConfig, segmentsGroupId: string) => {
      setSelectedLinkGroups(deleteSegmentFromGroup(selectedLinkGroups, segmentsGroupId, segment));
      updateMapOnDeleteSelectedLink(segment);
    },
    [selectedLinkGroups, updateMapOnDeleteSelectedLink],
  );

  const addSegmentFromPopup = useCallback(
    (selectedVolume: SelectedVolume, selectedRoadSegmentId: string, layerName: string) => {
      if (activeGroupId === "") return;

      const directionalSelectedVolume = getSelectedSegmentConfig(selectedVolume, selectedRoadSegmentId);

      // do this only if segment hasn't been already added to the list, otherwise ignore
      if (selectedRoadSegmentId && !segmentExistsInGroups(selectedLinkGroups, directionalSelectedVolume)) {
        const newGroups = addSegmentToGroups(selectedLinkGroups, activeGroupId, directionalSelectedVolume);

        setSelectedLinkGroups(newGroups);

        // add permanently highlighted feature for selected links (always displayed on the map)
        updateFeatureState(
          map.current!,
          ROADS_SOURCE_ID,
          layerName,
          directionalSelectedVolume?.fromToId,
          "permanentSelectHighlight",
          true,
        );

        // add permanent marker to map and state
        createMarkerAndAddToMap(directionalSelectedVolume, selectedLinkGroups);
      }
    },
    [map, activeGroupId, selectedLinkGroups, createMarkerAndAddToMap],
  );

  const handleChangeZoneSelectionMode = (newMode: ZoneSelectionMode) => {
    zoneSelectionModeRef.current = newMode;
  };

  const handleChangeActiveSegmentsGroup = (groupId: string) => {
    setActiveGroupId(groupId);
  };

  const handleDeleteSegmentsGroup = (groupId: string) => {
    const group = selectedLinkGroups.find((group) => group.groupName === groupId);
    const segments = new Set<string>();
    group?.segments.forEach((segment) => {
      updateMapOnDeleteSelectedLink(segment);
      segments.add(segment.segmentId);
    });
    setSelectLinkMarkers(selectLinkMarkers.filter(({ segmentId }) => !segments.has(segmentId)));
    setSelectedLinkGroups(selectedLinkGroups.filter((group) => group.groupName !== groupId));
  };

  const handleChangePredicateLogic = (predicateLogic: SelectLinkPredicateLogic) => {
    setSelectLinkPredicateLogic(predicateLogic);
  };

  const handleChangeOptions = (options: SelectLinkAnalysisOptions) => {
    setSelectLinkOptions(options);
  };

  const handleSaveSelectLinkConfiguration = (options: SelectLinkAnalysisOptions) => {
    updateSelectLinkConfiguration.current(
      selectedLinkGroups,
      selectedOriginsRef.current,
      selectedDestinationsRef.current,
      selectLinkPredicateLogic,
    );
  };

  /**
   * Handles click on the "Run Analysis" button in the right pane.
   */
  const handleRunAnalysis = (options: SelectLinkAnalysisOptions) => {
    updateSelectLinkConfiguration.current(
      selectedLinkGroups,
      selectedOriginsRef.current,
      selectedDestinationsRef.current,
      selectLinkPredicateLogic,
    );
    if (currentRoadFilters && selectedFocusArea) {
      getSelectLinkSegmentCounts.current(
        currentRoadFilters,
        selectedFocusArea,
        selectedLinkGroups,
        selectedOriginsRef.current,
        selectedDestinationsRef.current,
        selectLinkPredicateLogic,
        options,
      );

      addCustomGAEvent("select-link", "analysis", "run-analysis", user, userOrganizationName);

      setSelectLinkMode(SelectLinkMode.RESULTS);
    }
  };

  const handleConfigureAnalysis = () => {
    setSelectLinkMode(SelectLinkMode.LINKS);
  };

  const handleChangeSelectLinkMode = (index: string) => {
    setSelectLinkMode(index === "0" ? SelectLinkMode.LINKS : SelectLinkMode.RESULTS);
  };

  const loadingResults = selectLinkSegmentCounts.state === DataState.LOADING;

  return (
    <>
      <MapController
        map={map}
        layerManagerRef={layerManagerRef}
        mode={selectLinkMode}
        selectedLinkGroups={selectedLinkGroups}
        selectLinkOptions={selectLinkOptions}
        selectedOriginsRef={selectedOriginsRef}
        selectedDestinationsRef={selectedDestinationsRef}
        selectLinkConfigLinksModuleData={selectLinkConfigLinksModuleData}
        addSegmentFromPopup={addSegmentFromPopup}
        setSelectLinkConfigZonesModuleData={setSelectLinkConfigZonesModuleData}
        setSelectLinkConfigLinksModuleData={setSelectLinkConfigLinksModuleData}
        setSelectLinkResultsModuleData={setSelectLinkResultsModuleData}
        updateRoadsModeCounts={updateRoadsModeCounts}
        updateODModeCounts={updateODModeCounts}
        zoneSelectionModeRef={zoneSelectionModeRef}
        setActiveGroupId={setActiveGroupId}
        setSelectedOrigins={setSelectedOrigins}
        setSelectedDestinations={setSelectedDestinations}
        setSelectedLinkGroups={setSelectedLinkGroups}
        setMaxSegmentsGroupId={setMaxSegmentsGroupId}
        setSelectLinkPredicateLogic={setSelectLinkPredicateLogic}
        setSelectLinkOptions={setSelectLinkOptions}
        updateFeatureStateForRoads={updateFeatureStateForRoads}
        updateSelectLinkConfiguration={updateSelectLinkConfiguration}
        getSelectLinkSegmentCounts={getSelectLinkSegmentCounts}
      />
      <ToggleButtons
        leftButtonLabel="Configuration"
        rightButtonLabel="Results"
        activeIndex={selectLinkMode === SelectLinkMode.RESULTS ? "1" : "0"}
        onChangeIndex={handleChangeSelectLinkMode}
        leftButtonDisabled={false}
        rightButtonDisabled={!areSelectLinkResultsAvailable || areConfigChangesPending || areSelectLinkResultsEmpty}
        leftButtonIndex={"0"}
        rightButtonIndex={"1"}
        leftButtonVariant="leftsquared"
        rightButtonVariant="rightsquared"
        isMapToggle
      />
      <RightSidebar isOpen animation>
        {selectLinkMode === SelectLinkMode.ZONES ? (
          <SelectLinkZonesConfigPanel
            activeZoneSelectionMode={zoneSelectionModeRef.current}
            selectedOrigins={selectedOrigins}
            selectedDestinations={selectedDestinations}
            selectedZone={selectedZone}
            setSelectedZone={setSelectedZone}
            onChangeZoneSelectionMode={handleChangeZoneSelectionMode}
            deleteSelectedZone={deleteSelectedZone}
          />
        ) : null}
        {selectLinkMode === SelectLinkMode.LINKS || (selectLinkMode === SelectLinkMode.RESULTS && !loadingResults) ? (
          <SelectLinkLinksConfigPanel
            isResults={selectLinkMode === SelectLinkMode.RESULTS}
            activeGroupId={activeGroupId}
            maxGroupId={maxSegmentsGroupId}
            selectedLinkGroups={selectedLinkGroups}
            predicateLogic={selectLinkPredicateLogic}
            setActiveGroupId={setActiveGroupId}
            onAddNewSegmentsGroup={handleAddNewSegmentsGroup}
            onChangeActiveGroup={handleChangeActiveSegmentsGroup}
            onDeleteSegmentsGroup={handleDeleteSegmentsGroup}
            onZoomOnCoordinate={handleZoomOnSegment}
            onDeleteSelectedLink={handleDeleteSelectedLink}
            onChangePredicateLogic={handleChangePredicateLogic}
          />
        ) : null}
        {selectLinkMode === SelectLinkMode.RESULTS && loadingResults && (
          <SegmentsContainer>
            <CircularProgress />
          </SegmentsContainer>
        )}
        <SelectLinkConfigSettings
          loadingResults={loadingResults}
          isResults={selectLinkMode === SelectLinkMode.RESULTS}
          minVolume={selectLinkOptions.minVolume}
          minAllowedVolume={
            selectLinkConfigLinksModuleData?.data.minAllowedVolume ??
            selectLinkConfigZonesModuleData?.data.minAllowedVolume
          }
          selectedLinkGroups={selectedLinkGroups}
          onChangeOptions={handleChangeOptions}
          onSaveConfiguration={handleSaveSelectLinkConfiguration}
          onRunAnalysis={handleRunAnalysis}
          onConfigureAnalysis={handleConfigureAnalysis}
        />
      </RightSidebar>
      {isEmptyResultWarningOpen && (
        <EmptyResultWarning isOpen={isEmptyResultWarningOpen} onClose={() => setIsEmptyResultWarningOpen(false)} />
      )}
    </>
  );
};
