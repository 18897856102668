import styled from "@emotion/styled";
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Button, Dialog, FlexContainer, Input, TextArea } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { DataState } from "store/interfaces";
import { licenseActions } from "store/sections/license";

import { Image } from "types";

const Title = styled.h2`
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.h4`
  margin-bottom: 1.5rem;
  color: var(--color-textSecondary);
  font-weight: 400;
  max-width: 600px;
`;

const FieldContainer = styled.div`
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  display: inline-block;
  font-weight: 500;
  margin-bottom: 0.25rem;
`;

const ErrorMsg = styled.div`
  position: absolute;
  bottom: -2px;
  color: var(--color-red-rose);
  font-size: 12px;
  font-weight: 500;
  margin-left: 0.5rem;
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 200px;
`;

const Actions = styled(FlexContainer)`
  justify-content: flex-end;
  margin-top: 1.5rem;

  & button {
    margin-left: 1rem;
  }
`;

interface FormData {
  subject: string;
  message: string;
  image: Image | null;
}

interface HelpFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialSubject?: string;
}

export const HelpForm: FC<HelpFormProps> = ({ isOpen, onClose, initialSubject = "" }) => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const feedback = useAppSelector((state) => state.license.feedback);

  const validate = (values: FormData) => {
    const errors: any = {};

    if (!values.subject) {
      errors.subject = "Required";
    } else if (!values.message) {
      errors.message = "Required";
    }
    return errors;
  };

  const handleChangeFileInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    const fileReader = new FileReader();
    const image = event?.target?.files?.[0];
    if (image) {
      fileReader.onload = () => {
        if (fileReader.readyState === 2) {
          const res = fileReader.result as string;
          setFieldValue("image", {
            content: res.replace(`data:${image.type};base64,`, ""),
            contentType: image.type,
            fileName: image.name,
          });
        }
      };
      fileReader.readAsDataURL(image);
    }
  };

  const handleSubmit = (values: FormData) => {
    const { subject, message, image } = values;

    dispatch(
      licenseActions.sendFeedback({
        subject,
        body: message,
        url: pathname + search,
        images: image ? [image] : undefined,
      }),
    );
  };

  useEffect(() => {
    if (feedback.data) onClose();

    return () => {
      dispatch(licenseActions.clearFeedback());
    };
  }, [feedback.data, onClose, dispatch]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Title>Customer Feedback</Title>
      <SubTitle>
        We value your input! Let us know how we can improve by sending us a message with your suggestions, feature
        requests, or bug reports.
      </SubTitle>
      <Formik<FormData>
        initialValues={{ subject: initialSubject, message: "", image: null }}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <FieldContainer>
              <Label htmlFor="subject">Subject:*</Label>
              <Field as={Input} id="subject" name="subject" type="subject" />
              {errors.subject && touched.subject ? <ErrorMsg>{errors.subject}</ErrorMsg> : null}
            </FieldContainer>
            <FieldContainer>
              <Label htmlFor="message">Message:*</Label>
              <Field as={StyledTextArea} id="message" name="message" />
              {errors.message && touched.message ? <ErrorMsg>{errors.message}</ErrorMsg> : null}
            </FieldContainer>
            <FieldContainer>
              <Label htmlFor="image">Upload image:</Label>
              <Field name="image">
                {({ field, form: { errors }, meta }: any) => (
                  <div>
                    <input
                      id="image"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(event) => handleChangeFileInput(event, setFieldValue)}
                    />
                    {meta.touched && meta.error && <ErrorMsg>{errors.message}</ErrorMsg>}
                  </div>
                )}
              </Field>
              {errors.image && touched.image ? <ErrorMsg>{errors.image}</ErrorMsg> : null}
            </FieldContainer>
            <Actions>
              <Button type="button" color="whiteBlue" onClick={onClose} disabled={feedback.state === DataState.LOADING}>
                Cancel
              </Button>
              <Button type="submit" color="primaryLight" loading={feedback.state === DataState.LOADING}>
                Submit
              </Button>
            </Actions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
