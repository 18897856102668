import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";

import { themeColors, themePaddings } from "./themeConstants";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    elevated: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    dialogTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    dialogTitle?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    dialogTitle: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    screenline: {
      circle: string;
      line: string;
      volume: string;
    };
    candidate: string;
  }

  interface PaletteOptions {
    screenline?: {
      circle: string;
      line: string;
      volume: string;
    };
    candidate?: string;
  }
}

let theme = createTheme({
  palette: {
    primary: {
      main: themeColors.primary,
    },
    secondary: {
      main: themeColors.primaryLight,
      contrastText: themeColors.textContrast,
    },
    candidate: "#ec407a",
    screenline: {
      circle: "#004d20",
      line: "#008035",
      volume: "#00c853",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          alignItems: "center",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "6px",
          textTransform: "none",
        },
        outlinedPrimary: {
          borderColor: themeColors.gray300,
          color: themeColors.gray600,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&.MuiChip-outlined": {
            "&.MuiChip-colorPrimary": {
              backgroundColor: alpha(theme.palette.primary.light, 0.05),
            },
            "&.MuiChip-colorSecondary": {
              backgroundColor: alpha(theme.palette.secondary.light, 0.05),
            },
            "&.MuiChip-colorSuccess": {
              backgroundColor: alpha(theme.palette.success.light, 0.05),
            },
            "&.MuiChip-colorWarning": {
              backgroundColor: alpha(theme.palette.warning.light, 0.05),
            },
            "&.MuiChip-colorInfo": {
              backgroundColor: alpha(theme.palette.info.light, 0.05),
            },
            "&.MuiChip-colorError": {
              backgroundColor: alpha(theme.palette.error.light, 0.05),
            },
          },
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        size: "small",
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
        colorPrimary: {
          borderColor: themeColors.gray300,
          color: themeColors.gray600,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
        disableRipple: true,
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          dialogTitle: "span",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
          paddingBottom: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: themePaddings.xl,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: themeColors.textSecondary,
            fontSize: "14px",
          },

          "&.select-field .MuiInputLabel-outlined": {
            top: "-6px",
          },

          "&.select-field .MuiInputLabel-outlined.MuiFormLabel-filled": {
            top: 0,
          },

          "& .MuiInputBase-colorWarning .MuiOutlinedInput-notchedOutline": {
            borderColor: `${themeColors.warningStatus} !important`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: `${themePaddings.sm} ${themePaddings.md}`,
          fontSize: "14px",

          "& .MuiInputBase-input:focus": {
            borderColor: "transparent",
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: "secondary",
        size: "small",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          marginLeft: theme.spacing(1),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-inputSizeSmall": {
            fontSize: "14px",
          },
          "& .MuiInputBase-root": {
            backgroundColor: "white",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "35px",
          "& .MuiTabs-indicator": {
            backgroundColor: themeColors.primaryLight,
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        iconPosition: "start",
      },
      styleOverrides: {
        root: {
          fontSize: "14px",
          minHeight: "30px",
        },
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    dialogTitle: {
      display: "block",
      fontSize: 16,
      marginBottom: themePaddings.md,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
