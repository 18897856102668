import { useAuth0 } from "@auth0/auth0-react";
import styled from "@emotion/styled";
import { ChangeEvent, FC, useMemo, useState } from "react";

import { getActiveFilters } from "features/export";
import { buildFilters } from "features/filters/utils";

import { Button, Dialog, DialogProps, FlexContainer, TextArea } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { exportActions } from "store/sections/export";

import { AoiExportRequest } from "types";

import { addCustomGAEvent } from "utils/addCustomGAEvent";

import { FilterList, List, ListItem } from "../export/FilterList";

export interface RoadVmtExportDialogProps extends DialogProps {
  onClose: () => void;
}

const Label = styled.p`
  margin-bottom: 0.25rem;
  font-size: 12px;
  font-weight: 600;
`;

const ExportPlaceHolder = styled.div`
  height: calc(100% - 38px);
  min-height: 55px;
  max-height: 320px;
  max-width: 400px;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
`;

const SpaceBetweenContainer = styled(FlexContainer)`
  justify-content: space-between;
`;

const FiltersContainer = styled("div")`
  margin-top: 1rem;
`;

const ExportCard = styled("div")`
  height: calc(100% - 68px);
  min-height: 55px;
  max-height: 320px;
  max-width: 400px;
  overflow-y: auto;
  padding: 1rem;
  margin-bottom: 8px;
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  background: var(--color-text-field-gray);
`;

export const RoadVmtExportDialog: FC<RoadVmtExportDialogProps> = ({ onClose, ...props }) => {
  const { user } = useAuth0();
  const userOrganizationName = useAppSelector((state) => state.license.user.data?.organization?.name);

  const dispatch = useAppDispatch();

  const [exportDescription, setExportDescription] = useState("");

  const roadVmtMetadata = useAppSelector((state) => state.roadVmt.roadVmtMetadata);
  const focusAreas = useAppSelector((state) => state.analytics.focusAreasAndDatasets);
  const selectedFocusAreaId = useAppSelector((state) => state.global.selectedFocusAreaId);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);

  const currentFilters = useAppSelector((state) => state.roadVmt.filters);

  const isExportPermitted = roadVmtMetadata.data?.exportPermissions?.allowExport;

  const selectedArea = useMemo(
    () => focusAreas.data?.find((area) => area.id === selectedFocusAreaId) || null,
    [focusAreas.data, selectedFocusAreaId],
  );

  const onNewExport = () => {
    if (selectedArea && timePeriod) {
      const aoiExportRequest: AoiExportRequest = {
        exportDescription,
        areaOfInterestName: selectedArea.region,
        timePeriod,
        shouldGenerateSeqIds: true,
        roadVmt: {
          measures: {
            aadt_vmt: {
              filter: buildFilters(currentFilters),
            },
            mileage: {
              filter: buildFilters(currentFilters),
            },
          },
        },
      };

      addCustomGAEvent("new_export", "start_new_export", "aoi_export", user, userOrganizationName);
      dispatch(exportActions.addAOIExportJob(aoiExportRequest));

      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <h2 style={{ margin: "-2rem 0 1rem 0" }}>New Export</h2>
      <ExportPlaceHolder>
        <Label>Export will include:</Label>
        <List>
          <ListItem>Road VMT counts per county (csv)</ListItem>
          <ListItem>Road mileage per county (csv)</ListItem>
        </List>
      </ExportPlaceHolder>
      <Label>Export Description</Label>
      <TextArea
        id="exportDescription"
        value={exportDescription}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setExportDescription(e.target.value)}
      />

      <FiltersContainer>
        <Label>Filters</Label>
        <ExportCard>
          <FilterList filters={getActiveFilters(currentFilters)} />
        </ExportCard>
      </FiltersContainer>

      <SpaceBetweenContainer>
        <Button color="white" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!isExportPermitted} onClick={onNewExport}>
          Start export
        </Button>
      </SpaceBetweenContainer>
    </Dialog>
  );
};
