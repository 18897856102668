export enum MapBoxStyles {
  Default = "mapbox://styles/kcarter13/clb6ku0t5000c14ohgcdnee3e",
  Dark = "mapbox://styles/kcarter13/cjfo6d5eh2oyh2rmmepjct89m",
  Streets = "mapbox://styles/mapbox/streets-v12",
  Satellite = "mapbox://styles/mapbox/satellite-v9",
  SatelliteStreets = "mapbox://styles/mapbox/satellite-streets-v12",
}

// const buildEsriRasterStyles = (baseMap: string) => {
//   return {
//     version: 8,
//     sources: {
//       esriBasemap: {
//         type: "raster",
//         tiles: [`https://server.arcgisonline.com/ArcGIS/rest/services/${baseMap}/MapServer/tile/{z}/{y}/{x}`],
//         tileSize: 256,
//       },
//     },
//     layers: [
//       {
//         id: "esriBasemap",
//         type: "raster",
//         source: "esriBasemap",
//         minzoom: 0,
//         maxzoom: 22,
//       },
//     ],
//   };
// };

// enum EsriRasterMaps {
//   WorldStreetMap = "World_Street_Map",
// }

export const MAPBOX_STYLE_OPTIONS = [
  { value: MapBoxStyles.Default, label: "Default", icon: "map" },
  { value: MapBoxStyles.Dark, label: "Dark", icon: "moon" },
  { value: MapBoxStyles.Streets, label: "Streets", icon: "office" },
  { value: MapBoxStyles.Satellite, label: "Satellite", icon: "satellite" },
  {
    value: MapBoxStyles.SatelliteStreets,
    label: "Satellite & Streets",
    icon: "satellite",
  },
  // {
  //   value: buildEsriRasterStyles(EsriRasterMaps.WorldStreetMap),
  //   label: "Esri - World Street Map",
  //   icon: "map",
  // },
];
