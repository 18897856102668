import { Button, CircularProgress, Grid, MenuItem, Stack, styled } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { MuiDialog } from "components";

import { useAppDispatch, useAppSelector } from "hooks";

import { screenlinesActions } from "store/sections/screenlines";
import { datasetFoldersActions } from "store/sections/userFolders";

import { SmallTextField } from "./ScreenlineDetail";
import { getScreenlinesBboxPolygon } from "./utils";

const Dialog = styled(MuiDialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "400px",
    minHeight: "auto",
  },
}));

export const ScreenlineSaveDialog: FC = () => {
  const dispatch = useAppDispatch();

  const isTokenLoaded = useAppSelector((state) => state.analytics.authorizationTokenLoaded);
  const timePeriod = useAppSelector((state) => state.global.timePeriod);
  const datasetFoldersData = useAppSelector((state) => state.datasetFolders.folders.data);
  const screenlines = useAppSelector((state) => state.screenlines.screenlines);
  const isSaveScreenlinesDialogOpen = useAppSelector((state) => state.screenlines.isSaveScreenlinesDialogOpen);
  const loading = useAppSelector((state) => state.datasetFolders.loading);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState("");

  const folders = useMemo(() => Object.entries(datasetFoldersData?.folders || {}), [datasetFoldersData?.folders]);

  const handleSaveScreenlines = () => {
    if (timePeriod && name && selectedFolderId) {
      const polygon = getScreenlinesBboxPolygon(screenlines);

      dispatch(
        datasetFoldersActions.createConfigDocument({
          folderId: selectedFolderId,
          configDocumentType: "screenlines",
          configDocumentName: name,
          description: description,
          configPayload: screenlines,
          configSchemaVersion: "1.0",
          boundingBox: polygon,
          timePeriod: timePeriod,
        }),
      );
    }
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setSelectedFolderId("");
  };

  const handleCloseDialog = useCallback(() => {
    dispatch(screenlinesActions.setIsSaveScreenlineDialogOpen(false));
    resetForm();
  }, [dispatch]);

  useEffect(() => {
    if (isTokenLoaded) {
      dispatch(datasetFoldersActions.fetchFoldersStructure());
    }
  }, [isTokenLoaded, dispatch]);

  //If just one folder exist, set it to selected
  useEffect(() => {
    if (folders.length === 1) {
      setSelectedFolderId(folders[0][0]);
    }
  }, [folders]);

  return (
    <Dialog open={isSaveScreenlinesDialogOpen} onClose={handleCloseDialog} title="Save screenlines to document">
      <Grid padding={2} marginTop={"60px"}>
        <Stack spacing={2}>
          <SmallTextField
            label={"Folder"}
            select
            required
            value={selectedFolderId}
            disabled={loading || !folders.length}
            onChange={(e) => setSelectedFolderId(e.target.value)}
          >
            {folders.map(([folderId, folder]) => (
              <MenuItem key={folderId} value={folderId}>
                {folder.folderName}
              </MenuItem>
            ))}
          </SmallTextField>
          <SmallTextField
            label={"Document name"}
            required
            value={name}
            disabled={loading || !folders.length}
            onChange={(e) => setName(e.target.value)}
          />
          <SmallTextField
            label={"Description"}
            multiline
            minRows={2}
            maxRows={2}
            value={description}
            disabled={loading || !folders.length}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Grid container justifyContent={"flex-end"} alignItems={"center"} gap={1}>
            <Button size="small" color="secondary" variant="outlined" disabled={loading} onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              size="small"
              color="secondary"
              disabled={loading || !folders.length}
              onClick={handleSaveScreenlines}
              sx={{ height: 30 }}
            >
              {loading ? <CircularProgress size={14} color="inherit" /> : "Save"}
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Dialog>
  );
};
